import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  DatePicker,
  Col,
  Row,
  Icon,
  Input,
  Form,
  notification,
  Spin,
  Steps,
  Popover,
  Descriptions,
} from "antd";
import styled from "styled-components";
import { Cookies } from "react-cookie";
import withoutLogo from "../static/images/w-delay-logo.png";
import senderOne from "../static/images/sender-1.png";
import backButton from "../static/images/back-button.png";
import receiverTwo from "../static/images/receiver-2.png";
import withoutLogo1 from "../static/images/w-delay-logo.png";
import { slide as Menu } from "react-burger-menu";
import smallWave from "../static/images/small-wave.png";
import mapImgBig from "../static/images/home-map.webp";
import quickQuote from "../static/images/quick-quote.png";
import serviceType from "../static/images/service-type.png";
import shipping from "../static/images/shipping.png";
import pay from "../static/images/pay.png";

import "./sales-screen.css";
import { Link } from "react-router-dom";
import moment from "moment";
const { Step } = Steps;
const Notify = (type, title, message, duration) => {
  notification[type]({
    message: title,
    description: message
      ? message.message
        ? message.message
        : message
      : message,
    duration: duration ? duration : 4.5,
  });
};
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
const { Option } = Select;
const { Header } = Layout;

const { Search } = Input;
const ParcelSearchMobile = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    background-color: transparent;
    border: 2px solid #2482db;

    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .ant-btn-primary {
    border-radius: 0.3em;
    margin-left: -0.25em;
    background-color: #2482db;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`;
const ParcelSearch = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    background-color: transparent;
    border-right: 0px solid red !important;
    border: 2px solid #2482db;

    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .ant-input-group .ant-input {
    width: 98.9%;
  }
  .ant-btn-primary {
    border-radius: 0.3em;
    margin-left: -0.25em;
    background-color: #2482db;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-selection {
    border-radius: 0.3em;
    height: 3.65em;
    top: -0.25em;
  }
  .ant-select-selection__rendered {
    color: black;
    height: 3.4em;
  }
  .ant-select-selection-selected-value {
    line-height: 3.5em;
  }
`;
const StyledSelect1 = styled(Select)`
  .ant-select-selection {
    border-radius: 0em;
    height: 3.5em;
    top: -0.3em;
  }
  .ant-select-selection__rendered {
    height: 3.4em;
  }
  .ant-select-selection-selected-value {
    line-height: 3.5em;
  }
`;
const StyledInput = styled(Input)`
  ::placeholder {
    color: lightgrey !important;
  }
  @media (max-width: 780px) {
  }
`;
const StyledDatePicker = styled(DatePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 3.5em;
  }
`;
const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 10px !important;
  }
  @media (max-width: 1250px) {
    width: 13em;
  }
  @media (max-width: 580px) {
    margin: auto !important;
    width: 100% !important;
    margin-bottom: 3em !important;
    min-width: 25em !important;
  }
`;
const ParcelSteps = styled(Steps)`
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #2482db !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #2482db !important;
}.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background:  #2482db; transform:scale(2);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size:13px !important;
}

@media (max-width: 550px) {
  position:absolute;
  transform:scale(1);
  right:0em !important;
  display:none;
}
@media (max-width: 480px) {
  display:none !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: transparent;
    border: 1px solid #2482db;

  transform:scale(2);
}
`;
const ParcelStepsMobile = styled(Steps)`
display: none !important;

  @media (max-width: 550px) {
width:113vw;

    display: flex !important;
    transform: scale(0.9);
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: white;
    border: 1px solid #2482db;
    transform: scale(2);
  }
  }
`;
const TopHeader = (props) => {
  return (
    <Header>
      <div className="header-links-quote">
        {" "}
        <Link to="/get-quote">
          <a
            style={{
              color: "rgb(47, 99, 111)",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "2%",
              textDecoration: "underline",
            }}
          >
            Get a Quote
          </a>
        </Link>
        <Link
          style={{
            color: "#2F636F",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
          }}
          to="/community-projects"
        >
          Community Projects
        </Link>{" "}
        <Link
          style={{
            color: "#2F636F",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
          }}
          to="/contact-us"
        >
          Contact Us
        </Link>{" "}
        <ParcelSearch
          className="track-parcel"
          style={{
            marginTop: "0.6em",
            width: "23em",
            paddingLeft: "4%",
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={(value) => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            // props.history.push("/track-trace")
          }}
        />
      </div>{" "}
      <Menu burgerBarClassName={"my-class"} right>
        <ul id="menu">
          <img
            className="home-logo"
            src={withoutLogo1}
            alt="Logo"
            style={{
              position: "absolute",
              top: "0em",
              left: "3em",
              paddingLeft: "0em",
              zIndex: 0,
            }}
          ></img>{" "}
          <div class="pin1"></div>
          <div class="pulse1"></div>
        </ul>
        <ParcelSearchMobile
          id="IdTracker"
          className="track-parcel"
          style={{
            position: "absolute",
            marginTop: "-6em",
            paddingLeft: "10%",
            paddingRight: "20%",
            width: "100%",
            zIndex: 2,
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={(value) => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        />
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/">
          Home
        </Link>
        <Link
          style={{ fontSize: "20px", color: "#2482db" }}
          to="/community-projects"
        >
          Community Projects
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/contact-us">
          Contact Us
        </Link>
        <Link
          style={{
            fontSize: "20px",
            color: "#2482db",
            textDecoration: "underline",
          }}
          to="/get-quote"
        >
          Get a Quote
        </Link>
      </Menu>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "1.4em",
        bottom: 0,
      }}
      className="footer-watermark-quote"
    >
      <img
        src={smallWave}
        alt="Logo"
        style={{
          zIndex: -1,

          bottom: 0,
          left: 0,
          width: "100vw",
          minHeight: "10em",
          minWidth: "60em",
        }}
      ></img>
      <p
        style={{
          color: "white",
          marginTop: "-2.7em",
          fontSize: "12px",
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        Copyright © 2020. Without Delay Logistics All rights reserved.
      </p>
    </div>
  );
};

class SalesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectSuburbs: [],
      deliverSuburbs: [],
      serviceTypes: undefined,
      quote: undefined,
      currentStep: 0,
      waybill: window.waybillNumber,
      loading: false,
      details: undefined,
      isData: true,
    };
  }
  prev = () => {
    const currentStep = this.state.currentStep - 1;
    this.setState({ currentStep });
  };
  fetchWaybillDetails = (value) => {
    this.setState({ waybill: value }, () => {
      this.componentWillMount();
    });
  };
  componentWillMount = () => {
    this.setState({ loading: true });
    if (
      new URLSearchParams(this.props.history.location.search).get("status") ===
      "complete"
    ) {
      const quoteno = new URLSearchParams(
        this.props.history.location.search
      ).get("quoteno");
      this.setState({ loading: true });
      fetch(
        "https://www.vcsappcloud.com/withoutdelay/api/quote/getwaybill?quoteno=" +
          quoteno,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async (res) => {
        if (!res.ok) {
          this.setState({ loading: false });
          Notify("error", "Error retrieving waybill");
        }
        this.setState({
          loading: false,
          waybill: await res.json(),
          complete: true,
        });
      });
    } else if (
      new URLSearchParams(this.props.history.location.search).get("status") ===
      "cancel"
    ) {
      let searchParams = new URLSearchParams(
        this.props.history.location.search
      );
      let retryPayment = {
        quoteno: searchParams.get("quoteno"),
        total: searchParams.get("total"),
        name: searchParams.get("name"),
        specins: searchParams.get("specins"),
        starttime: searchParams.get("starttime"),
        endtime: searchParams.get("endtime"),
        collectiondate: searchParams.get("collectiondate"),
        token: searchParams.get("token"),
      };
      this.setState({
        retryPayment: retryPayment,
        cancelled: true,
        loading: false,
      });
    } else {
      fetch("https://www.vcsappcloud.com/withoutdelay/api/quote/gettoken", {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then(async (res) => {
        if (!res.ok) {
          this.setState({ loading: false });
          Notify("error", "Error retrieving token");
        }
        const cookies = new Cookies();
        cookies.set("token", await res.text(), { path: "/" });
        this.setState({ loading: false });
      });
    }
  };
  handleSearchCollectSuburb = (value) => {
    if (value.length > 4) {
      const cookies = new Cookies();
      const token = cookies.get("token");
      fetch(
        "https://www.vcsappcloud.com/withoutdelay/api/quote/getsuburbs?token=" +
          token +
          "&query=" +
          value,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async (res) => {
        if (!res.ok) {
          Notify("error", "Error retrieving places");
        }
        this.setState({ collectSuburbs: await res.json() });
      });
    }
  };
  handleSearchDeliverSuburb = (value) => {
    if (value.length > 4) {
      const cookies = new Cookies();
      const token = cookies.get("token");
      fetch(
        "https://www.vcsappcloud.com/withoutdelay/api/quote/getsuburbs?token=" +
          token +
          "&query=" +
          value,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async (res) => {
        if (!res.ok) {
          Notify("error", "Error retrieving places");
        }
        this.setState({ deliverSuburbs: await res.json() });
      });
    }
  };
  getServiceTypes = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let params = {
          ttype: "I",
          details: {
            origplace: parseInt(this.state.origplace),
            destplace: parseInt(this.state.destplace),
            parclength: parseInt(this.state.parclength),
            parcwidth: parseInt(this.state.parcwidth),
            parcheight: parseInt(this.state.parcheight),
            parcweight: parseInt(this.state.parcweight),
            parcdesc: parseInt(this.state.parcdesc),
            parcquantity: parseInt(this.state.parcquantity),
          },
          contents: [
            {
              pieces: this.state.pieces,
              description: this.state.description,
              actmass: this.state.actmass,
              dim1: this.state.dim1,
              dim2: this.state.dim2,
              dim3: this.state.dim3,
              item: 1,
            },
          ],
        };
        const cookies = new Cookies();
        let body = {
          params: params,
          token: cookies.get("token"),
        };
        fetch(
          "https://www.vcsappcloud.com/withoutdelay/api/quote/getservicetypes",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(body),
          }
        ).then(async (res) => {
          if (!res.ok) {
            Notify("error", "Error retrieving service types");
          }
          let serviceTypes = await res.json();
          for (var i = 0; i < serviceTypes[0].rates.length; i++) {
            if (serviceTypes[0].rates[i].name === "Local Economy Delivery") {
              serviceTypes[0].rates[i].description =
                "48-72 hour delivery service";
            }
            if (
              serviceTypes[0].rates[i].name.search(
                "Local Early Morning Delivery"
              ) > -1
            ) {
              serviceTypes[0].rates[i].description =
                "Delivery before 9AM the following business day";
            }
            if (serviceTypes[0].rates[i].name === "Local Express Road") {
              serviceTypes[0].rates[i].description =
                "24-48 hour delivery service";
            }
            if (serviceTypes[0].rates[i].name === "Local Overnight Delivery") {
              serviceTypes[0].rates[i].description =
                "Delivery the next business day by 12pm";
            }
            if (serviceTypes[0].rates[i].name === "Local Same day Delivery") {
              serviceTypes[0].rates[i].description =
                "Delivery with in the same day";
            }
            if (serviceTypes[0].rates[i].name === "Economy") {
              serviceTypes[0].rates[i].description =
                "48-72 hour delivery service";
            }
            if (serviceTypes[0].rates[i].name === "Early Morning Delivery") {
              serviceTypes[0].rates[i].description =
                "Delivery before 9AM following business day, Major Cities only";
            }
            if (serviceTypes[0].rates[i].name === "Express Road") {
              serviceTypes[0].rates[i].description =
                "24-48 hour delivery service";
            }
            if (serviceTypes[0].rates[i].name === "Overnight Express") {
              serviceTypes[0].rates[i].description =
                "Delivery the next business day by 12pm";
            }
            if (serviceTypes[0].rates[i].name === "Same day") {
              serviceTypes[0].rates[i].description =
                "Delivery with in the same day Major Cities only";
            }
          }
          this.setState({
            serviceTypes: serviceTypes,
            currentStep: 1,
            loading: false,
          });
        });
      }
    });
  };
  updateServiceType = (service) => {
    this.setState({ quote: true, currentStep: 2, serviceType: service });
  };
  handleSummary = (event) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.collectiondate = moment(values.collectionDate).format(
          "DD.MM.YYYY"
        );
        values.collectionDate = moment(values.collectionDate).format(
          "YYYY-MM-DD"
        );
        values.starttime = values.collectionTime.split("-")[0];
        values.endtime = values.collectionTime.split("-")[1];
        values.quoteno = this.state.serviceTypes[0].quoteno;
        values.origplace = parseInt(this.state.origplace);
        values.destplace = parseInt(this.state.destplace);
        values.parclength = parseInt(this.state.parclength);
        values.parcwidth = parseInt(this.state.parcwidth);
        values.parcheight = parseInt(this.state.parcheight);
        values.parcweight = parseInt(this.state.parcweight);
        values.parcdesc = parseInt(this.state.parcdesc);
        values.parcquantity = parseInt(this.state.parcquantity);
        values.origperpcode = this.state.origperpcode;
        values.destperpcode = this.state.destperpcode;
        values.origtown = this.state.origtown;
        values.desttown = this.state.desttown;
        values.service = this.state.serviceType;
        values.contents = [
          {
            pieces: this.state.pieces,
            description: this.state.description,
            actmass: this.state.actmass,
            dim1: this.state.dim1,
            dim2: this.state.dim2,
            dim3: this.state.dim3,
            item: 1,
          },
        ];
        this.setState({ summary: values, currentStep: 3 });
      }
    });
  };
  handlePay = (event) => {
    this.setState({ loading: true });
    const cookies = new Cookies();
    let body = {
      quoteData: this.state.summary,
      token: cookies.get("token"),
    };
    fetch(
      "https://www.vcsappcloud.com/withoutdelay/api/quote/convertquote",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    ).then(async (res) => {
      if (!res.ok) {
        Notify("error", "Error processing payment");
      }
      this.makePayment(await res.json());
    });
  };

  makePayment = (finalQuote) => {
    const cookies = new Cookies();
    for (var i = 0; i < finalQuote.rates.length; i++) {
      if (finalQuote.rates[i].service === this.state.summary.service) {
        finalQuote.rates = finalQuote.rates[i];
        break;
      }
    }
    const specins = this.state.summary.specins
      ? this.state.summary.specins
      : "None";
    var params = {
      merchant_id: "15075409",
      merchant_key: "8incp77sr61lo",
      return_url:
        "https://www.withoutdelaycouriers.co.za/get-quote/?status=complete&quoteno=" +
        finalQuote.quoteno,
      cancel_url:
        "https://www.withoutdelaycouriers.co.za/get-quote/?status=cancel&quoteno=" +
        finalQuote.quoteno +
        "&total=" +
        finalQuote.rates.total +
        "&name=" +
        finalQuote.rates.name +
        "&specins=" +
        specins +
        "&starttime=" +
        this.state.summary.starttime +
        "&endtime=" +
        this.state.summary.endtime +
        "&collectiondate=" +
        this.state.summary.collectiondate +
        "&token=" +
        cookies.get("token"),
      notify_url:
        "https://www.vcsappcloud.com/withoutdelay/api/quote/convertwaybill",
      amount: finalQuote.rates.total,
      item_name:
        "Without Delay Logistics " + finalQuote.rates.name + " Service",
      item_description: finalQuote.rates.name + " service payment",
      custom_str1: finalQuote.quoteno,
      custom_str2: this.state.summary.specins ? this.state.summary.specins : "",
      custom_str3:
        this.state.summary.starttime + "-" + this.state.summary.endtime,
      custom_str4: this.state.summary.collectiondate,
      custom_str5: cookies.get("token"),
    };
    params.cancel_url = params.cancel_url.replace(/ /g, "%20");
    fetch("https://www.vcsappcloud.com/withoutdelay/api/quote/gethash", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
    }).then(async (res) => {
      if (!res.ok) {
        Notify("error", "Error processing payment");
      }
      params.signature = await res.text();
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", "https://www.payfast.co.za/eng/process");
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", params[key]);
          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
    });
  };

  retryPayment = () => {
    const specins = this.state.retryPayment.specins
      ? this.state.retryPayment.specins
      : "None";
    var params = {
      merchant_id: "15075409",
      merchant_key: "8incp77sr61lo",
      return_url:
        "https://www.withoutdelaycouriers.co.za/get-quote/?status=complete&quoteno=" +
        this.state.retryPayment.quoteno,
      cancel_url:
        "https://www.withoutdelaycouriers.co.za/get-quote/?status=cancel&quoteno=" +
        this.state.retryPayment.quoteno +
        "&total=" +
        this.state.retryPayment.total +
        "&name=" +
        this.state.retryPayment.name +
        "&specins=" +
        specins +
        "&starttime=" +
        this.state.retryPayment.starttime +
        "&endtime=" +
        this.state.retryPayment.endtime +
        "&collectiondate=" +
        this.state.retryPayment.collectiondate +
        "&token=" +
        this.state.retryPayment.token,
      notify_url:
        "https://www.vcsappcloud.com/withoutdelay/api/quote/convertwaybill",
      amount: this.state.retryPayment.total,
      item_name:
        "Without Delay Logistics " + this.state.retryPayment.name + " Service",
      item_description: this.state.retryPayment.name + " service payment",
      custom_str1: this.state.retryPayment.quoteno,
      custom_str2: this.state.retryPayment.specins
        ? this.state.retryPayment.specins
        : "",
      custom_str3:
        this.state.retryPayment.starttime +
        "-" +
        this.state.retryPayment.endtime,
      custom_str4: this.state.retryPayment.collectiondate,
      custom_str5: this.state.retryPayment.token,
    };
    params.cancel_url = params.cancel_url.replace(/ /g, "%20");
    fetch("https://www.vcsappcloud.com/withoutdelay/api/quote/gethash", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
    }).then(async (res) => {
      if (!res.ok) {
        Notify("error", "Error processing payment");
      }
      params.signature = await res.text();
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", "https://www.payfast.co.za/eng/process");
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", params[key]);
          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
    });
  };

  downloadWaybill = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = "data:application/pdf;base64," + this.state.waybill.waybillBase64;
    a.download = this.state.waybill.waybillno + ".pdf";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(a.href);
    Notify("success", "Successfully downloaded document");
  };
  render() {
    return (
      <React.Fragment>
        {" "}
        <Link to="/">
          <img
            className="home-logo"
            src={withoutLogo}
            alt="Logo"
            style={{
              marginTop: "0.8em",
              paddingLeft: "3em",
              zIndex: 0,
            }}
          ></img>
        </Link>
        <TopHeader
          history={this.props.history}
          fetchWaybillDetails={this.fetchWaybillDetails}
        />
        <div
          className="tracker-line"
          style={{
            position: "relative",
            width: "100%",

            maxWidth: "1200px",
            margin: "auto",
            padding: "0 24px",
            marginTop: "10em",
            transition: "all 0s ease",
          }}
        >
          {" "}
          <img
            className="quick-pic"
            src={quickQuote}
            alt="Logo"
            style={{
              marginTop: "-5em",
              marginLeft: "2.5em",
            }}
          ></img>{" "}
          <img
            className="service-pic"
            src={serviceType}
            alt="Logo"
            style={{
              position: "absolute",
              marginTop: "-2.5em",
              right: "61.3%",
            }}
          ></img>{" "}
          <img
            className="shipping-pic"
            src={shipping}
            alt="Logo"
            style={{
              position: "absolute",
              marginTop: "-2.5em",
              right: "33.6%",
            }}
          ></img>{" "}
          <img
            className="pay-pic"
            src={pay}
            alt="Logo"
            style={{
              position: "absolute",
              marginTop: "-2.5em",
              right: "6%",
            }}
          ></img>
          <ParcelSteps current={this.state.currentStep} progressDot={customDot}>
            <Step title="Quick Quote" description="" />
            <Step title="Service Type" description="" />
            <Step title="Shipping Information" description="" />
            <Step title="Confirm & Pay" description="" />
          </ParcelSteps>
          <ParcelStepsMobile
            current={this.state.currentStep}
            progressDot={customDot}
          >
            <Step title="Quick Quote" description="" />
            <Step title="Service Type" description="" />
            <Step title="Shipping Information" description="" />
            <Step title="Confirm & Pay" description="" />
          </ParcelStepsMobile>
        </div>
        <Spin style={{ height: "5em" }} spinning={this.state.loading}>
          {this.state.currentStep === 0 &&
          !this.state.complete &&
          !this.state.cancelled ? (
            <Card
              className="parcel-details-card"
              style={{
                maxWidth: "150em",
                width: "100%",
                height: "32em",
                minHeight: "60em",
                margin: "auto",
                border: "none",
                backgroundColor: "transparent",
                borderRadius: "1em",
                textAlign: "center",
                paddingTop: "0em",
                marginTop: "-4em",

                transform: "scale(0.8)",
              }}
            >
              <Row
                style={{
                  marginTop: "1em",
                }}
                gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}
              >
                <Col span={4}></Col>
                <Col sm={24} md={8} lg={8}>
                  {" "}
                  <p
                    className="select-delivery-collect-p"
                    style={{
                      paddingLeft: "1.2em",
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "#555E61",
                      fontSize: "20px",
                      paddingTop: "1em",
                      marginBottom: "0.4em",
                    }}
                  >
                    Collecting Package From
                  </p>
                  <Form.Item>
                    {this.props.form.getFieldDecorator("origplace", {
                      rules: [
                        {
                          required: true,
                          message: "Collection location required",
                        },
                      ],
                    })(
                      <StyledSelect
                        className="select-delivery-collect"
                        showSearch
                        style={{
                          width: "90%",
                          height: "3em",
                        }}
                        // value={this.state.origplace}
                        placeholder="Enter 5 characters to start search..."
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.handleSearchCollectSuburb}
                        onChange={(e, a) =>
                          this.setState({
                            origplace: a.key,
                            origperpcode: a.props.data,
                            origtown: a.props.children,
                          })
                        }
                        notFoundContent={null}
                      >
                        {this.state.collectSuburbs.map((d) => (
                          <Option data={d.pcode} key={d.place}>
                            {d.town}
                          </Option>
                        ))}
                      </StyledSelect>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={8} lg={8}>
                  {" "}
                  <p
                    className="select-delivery-collect-p"
                    style={{
                      paddingLeft: "1.2em",
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "#555E61",
                      fontSize: "20px",
                      paddingTop: "1em",
                      marginBottom: "0.4em",
                    }}
                  >
                    Delivering Package To
                  </p>{" "}
                  <Form.Item>
                    {this.props.form.getFieldDecorator("destplace", {
                      rules: [
                        {
                          required: true,
                          message: "Delivery location required",
                        },
                      ],
                    })(
                      <StyledSelect
                        showSearch
                        className="select-delivery-collect"
                        style={{ width: "90%", height: "3em" }}
                        // value={this.state.destplace}
                        placeholder="Enter 5 characters to start search..."
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.handleSearchDeliverSuburb}
                        onChange={(e, a) =>
                          this.setState({
                            destplace: a.key,
                            destperpcode: a.props.data,
                            desttown: a.props.children,
                          })
                        }
                        notFoundContent={null}
                      >
                        {this.state.deliverSuburbs.map((d) => (
                          <Option data={d.pcode} key={d.place}>
                            {d.town}
                          </Option>
                        ))}
                      </StyledSelect>
                    )}{" "}
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "1em",
                }}
              >
                <Col className="dimensions-col" sm={24} md={24} lg={24}>
                  {" "}
                  <Row>
                    {" "}
                    <Col className="length-col" style={{ zIndex: 4 }} span={12}>
                      {" "}
                      <p
                        className="length-col-header"
                        style={{
                          marginLeft: "34.3%",
                          paddingLeft: "1.2em",
                          textAlign: "left",
                          fontWeight: "bold",
                          color: "#555E61",
                          fontSize: "20px",
                          paddingTop: "1em",
                          marginBottom: "0.4em",
                        }}
                      >
                        Length
                      </p>{" "}
                      <Form.Item>
                        {this.props.form.getFieldDecorator("parclength", {
                          rules: [
                            {
                              required: true,
                              message: "Parcel length required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              marginLeft: "33%",
                              width: "58%",
                              height: "3.5em",
                              borderRadius: "0.4em",
                              fontSize: "15px",
                            }}
                            placeholder="10 - 120cm"
                            onChange={(e) =>
                              this.setState({ dim1: e.currentTarget.value })
                            }
                          />
                        )}{" "}
                      </Form.Item>
                    </Col>
                    <Col className="width-col" style={{ zIndex: 3 }} span={12}>
                      {" "}
                      <p
                        className="width-col-header"
                        style={{
                          marginLeft: "1%",
                          paddingLeft: "1.2em",
                          textAlign: "left",
                          fontWeight: "bold",
                          color: "#555E61",
                          fontSize: "20px",
                          paddingTop: "1em",
                          marginBottom: "0.4em",
                        }}
                      >
                        Width
                      </p>{" "}
                      <Form.Item>
                        {this.props.form.getFieldDecorator("parcwidth", {
                          rules: [
                            {
                              required: true,
                              message: "Parcel width required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              marginLeft: "-33%",

                              width: "58%",
                              height: "3.5em",
                              borderRadius: "0.4em",
                              fontSize: "15px",
                            }}
                            placeholder="10 - 60cm"
                            onChange={(e) =>
                              this.setState({ dim2: e.currentTarget.value })
                            }
                          />
                        )}{" "}
                      </Form.Item>
                    </Col>{" "}
                    <Col className="height-col" style={{ zIndex: 2 }} span={12}>
                      {" "}
                      <p
                        className="height-col-header"
                        style={{
                          marginLeft: "34.3%",
                          paddingLeft: "1.2em",
                          textAlign: "left",
                          fontWeight: "bold",
                          color: "#555E61",
                          fontSize: "20px",
                          paddingTop: "1em",
                          marginBottom: "0.4em",
                        }}
                      >
                        Height
                      </p>{" "}
                      <Form.Item>
                        {this.props.form.getFieldDecorator("parcheight", {
                          rules: [
                            {
                              required: true,
                              message: "Parcel height required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              marginLeft: "33%",

                              width: "58%",
                              height: "3.5em",
                              borderRadius: "0.4em",
                              fontSize: "15px",
                            }}
                            placeholder="2 - 60cm"
                            onChange={(e) =>
                              this.setState({ dim3: e.currentTarget.value })
                            }
                          />
                        )}{" "}
                      </Form.Item>
                    </Col>{" "}
                    <Col className="weight-col" style={{ zIndex: 2 }} span={12}>
                      {" "}
                      <p
                        className="weight-col-header"
                        style={{
                          marginLeft: "1%",
                          paddingLeft: "1.2em",
                          textAlign: "left",
                          fontWeight: "bold",
                          color: "#555E61",
                          fontSize: "20px",
                          paddingTop: "1em",
                          marginBottom: "0.4em",
                        }}
                      >
                        Weight
                      </p>{" "}
                      <Form.Item>
                        {this.props.form.getFieldDecorator("parcweight", {
                          rules: [
                            {
                              required: true,
                              message: "Parcel weight required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              marginLeft: "-33%",

                              width: "58%",
                              height: "3.5em",
                              borderRadius: "0.4em",
                              fontSize: "15px",
                            }}
                            placeholder="0.1 - 30kg"
                            onChange={(e) =>
                              this.setState({ actmass: e.currentTarget.value })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>{" "}
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "0em",
                }}
              >
                <Col span={4}></Col>{" "}
                <Col sm={24} md={8} lg={8}>
                  {" "}
                  <p
                    className="select-delivery-collect-p"
                    style={{
                      paddingLeft: "1.6em",
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "#555E61",
                      fontSize: "20px",
                      paddingTop: "1em",
                      marginBottom: "0.4em",
                    }}
                  >
                    Description
                  </p>{" "}
                  <Form.Item>
                    {this.props.form.getFieldDecorator("parcdesc", {
                      rules: [
                        {
                          required: true,
                          message: "Description required",
                        },
                      ],
                    })(
                      <StyledSelect
                        className="select-delivery-collect"
                        style={{
                          color: "lightgrey",
                          width: "88%",
                        }}
                        placeholder="Description"
                        onChange={(e) => this.setState({ description: e })}
                      >
                        <Option value="Box">Box</Option>
                        <Option value="Flyer Bag">Flyer Bag</Option>
                        <Option value="Pallet">Pallet</Option>
                        <Option value="Crate">Crate</Option>
                      </StyledSelect>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={8} lg={8}>
                  {" "}
                  <p
                    className="select-delivery-collect-p"
                    style={{
                      paddingLeft: "1.6em",
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "#555E61",
                      fontSize: "20px",
                      paddingTop: "1em",
                      marginBottom: "0.4em",
                    }}
                  >
                    Quantity
                  </p>{" "}
                  <Form.Item>
                    {this.props.form.getFieldDecorator("parcquantity", {
                      rules: [
                        {
                          required: true,
                          message: "Quantity required",
                        },
                      ],
                    })(
                      <StyledInput
                        className="select-delivery-collect"
                        style={{
                          width: "88%",
                          height: "3.5em",
                          borderRadius: "0.3em",
                          fontSize: "15px",
                        }}
                        placeholder="Quantity"
                        onChange={(e) =>
                          this.setState({ pieces: e.currentTarget.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>{" "}
              <Col style={{ textAlign: "right" }} span={24}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#555E61",
                    fontSize: "20px",
                    paddingTop: "1em",
                    marginBottom: "0.4em",
                  }}
                >
                  Note: Dimensions can affect price.
                </p>
              </Col>
              <Row gutter={24}>
                <Col style={{ textAlign: "center" }} span={24}>
                  <Link to="/get-quote">
                    <Button
                      className="calculate-button"
                      style={{
                        color: "white",
                        backgroundColor: "#2482DB",
                        fontSize: "22px",
                        fontWeight: "700",
                        width: "20%",
                        minWidth: "13em",
                        height: "2.8em",
                        marginTop: "2em",

                        borderRadius: "3em",
                        zIndex: 99,
                      }}
                      onClick={this.getServiceTypes}
                    >
                      Calculate
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          ) : null}
          {this.state.currentStep === 1 ? (
            <Card
              className="get-quote-card"
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
                margin: "auto",
                width: "90%",
                maxWidth: "110em",
                minWidth: "40em",
                border: "0px solid #42A5F6",
                borderRadius: "1em",
                textAlign: "center",
                top: "3em",
              }}
            >
              {" "}
              <img
                className="back-button"
                src={backButton}
                onClick={() => this.prev()}
                alt="Logo"
                style={{
                  position: "absolute",
                  top: "54%",
                  left: "0em",
                  cursor: "pointer",
                }}
              ></img>{" "}
              <Row type="flex" justify="space-around">
                {this.state.serviceTypes[0].rates.map((rate) => (
                  <Col sm={8} md={4}>
                    <StyledCard
                      style={{
                        width: "115%",
                        // minWidth: "19em",
                        // margin: "1em",
                        // height: "26em",
                        // borderRadius: "0.3em",
                        // borderLeft: "1px solid lightgrey",
                        // borderRight: "1px solid lightgrey",
                        // borderTop: "1px solid lightgrey",
                        // borderBottom: "1px solid lightgrey",
                      }}
                    >
                      <h2
                        // className="rates-card-header"
                        style={{
                          backgroundColor: "#E0F0FD",
                          color: "#2482DB",
                          fontSize: "20px",
                          fontWeight: "400",
                          height: 60,
                        }}
                      >
                        {rate.name}
                      </h2>
                      <h4
                        style={{
                          height: 42,
                        }}
                      >
                        {rate.description}
                      </h4>
                      <h1
                        // className="rates-card-total"
                        style={{
                          marginTop: "1.2em",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        {rate.total}
                      </h1>
                      <h5 style={{ fontWeight: "200", fontSize: "18px" }}>
                        incl. VAT
                      </h5>
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2482DB",
                          fontSize: "22px",
                          fontWeight: "700",
                          width: "70%",
                          minWidth: "5em",
                          height: "2.2em",
                          marginTop: "1.2em",

                          borderRadius: "0.3em",
                        }}
                        onClick={(e) => this.updateServiceType(rate.service)}
                      >
                        Select
                      </Button>
                    </StyledCard>
                  </Col>
                ))}
              </Row>
            </Card>
          ) : null}
          {this.state.currentStep === 2 ? (
            <Card
              className="get-quote-card"
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
                margin: "auto",

                minWidth: "35em",
                maxWidth: "69em",
                border: "0px solid #42A5F6",
                borderRadius: "1em",
                textAlign: "center",
                top: "3em",
              }}
            >
              <img
                className="back-button"
                src={backButton}
                onClick={() => this.prev()}
                alt="Logo"
                style={{
                  position: "absolute",
                  top: "54%",
                  left: "0em",
                  cursor: "pointer",
                }}
              ></img>{" "}
              <h1
                className="get-quote-header"
                style={{
                  backgroundColor: "#2482DB",
                  borderRadius: "0.1em",
                  fontSize: "24px",
                  color: "white",
                  marginTop: "0.5em",
                  paddingBottom: "0em",
                  border: "1px solid #2482db",
                }}
              >
                <img
                  className="number-1"
                  src={senderOne}
                  alt="Logo"
                  style={{
                    position: "absolute",
                    transform: "scale(0.8)",
                    left: "1.5em",
                  }}
                ></img>{" "}
                Sender’s Information
              </h1>
              <Form
                onSubmit={this.handleSummary}
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "1em",
                }}
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 12,
                }}
                layout="horizontal"
              >
                <h1
                  className="get-quote-notice1"
                  style={{
                    textAlign: "left",
                    color: "#214C63",
                    fontSize: "11px",
                    marginTop: "0.5em",
                    marginBottom: "0.5em",
                    paddingBottom: "0.8em",
                    paddingBottom: "0.8em",
                    borderTop: "0px solid #2482db",
                  }}
                >
                  Please ensure that you are available at this address in the{" "}
                  <br /> afternoon between 12:00-17:00
                </h1>{" "}
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("origpers", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Company (if applicable)"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("origpercontact", {
                        rules: [
                          { required: true, message: "Contact name required" },
                        ],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Contact name"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("origperphone", {
                        rules: [
                          { required: true, message: "Phone number required" },
                        ],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Telephone"
                          maxLength={10}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("origpercell", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Cellphone"
                          maxLength={10}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("origperadd1", {
                        rules: [
                          {
                            required: true,
                            message: "Street address required",
                          },
                        ],
                      })(
                        <Input
                          style={{
                            width: "185%",
                            height: "3.5em",
                            marginBottom: "0.5em",
                          }}
                          placeholder="Pick-up Street Address"
                        />
                      )}
                      {this.props.form.getFieldDecorator("origperadd2", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{
                            width: "185%",
                            height: "3.5em",
                            marginBottom: "0.5em",
                          }}
                          placeholder=""
                        />
                      )}
                      {this.props.form.getFieldDecorator("origperadd3", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder=""
                        />
                      )}
                    </Form.Item>{" "}
                  </Col>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("origtown", {
                        rules: [{ required: false }],
                        initialValue: this.state.origtown
                          ? this.state.origtown
                          : "BOKSBURG",
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Pick-up Town"
                          disabled={true}
                        />
                      )}
                    </Form.Item>{" "}
                    <div
                      className="get-quote-notice"
                      style={{
                        marginLeft: "7.6em",
                      }}
                    >
                      <h1
                        style={{
                          textAlign: "left",
                          color: "#214C63",
                          fontSize: "12px",
                          marginTop: "0.8em",
                          marginBottom: "0em",
                          paddingBottom: "0.8em",
                          borderTop: "0px solid #2482db",
                        }}
                      >
                        Please note{" "}
                      </h1>{" "}
                      <p
                        style={{
                          textAlign: "left",
                          lineHeight: "10px",
                          fontSize: "10px",
                          color: "#214C63",
                        }}
                      >
                        Booking cutoff time us 14H30 (2:30pm) for current day
                        delivery
                      </p>{" "}
                      <p
                        style={{
                          textAlign: "left",
                          lineHeight: "10px",
                          fontSize: "10px",
                          color: "#214C63",
                        }}
                      >
                        Pick-ups cannot occur on Weekends or Public holidays
                      </p>{" "}
                      <p
                        style={{
                          textAlign: "left",
                          lineHeight: "10px",
                          fontSize: "10px",
                          color: "#214C63",
                        }}
                      >
                        Drivers do not call on approach to pickup or deliver
                      </p>{" "}
                      <p
                        style={{
                          textAlign: "left",
                          lineHeight: "10px",
                          fontSize: "10px",
                          color: "#214C63",
                        }}
                      >
                        We do not service government departments, casinos,
                        schools, mines, airports,
                      </p>{" "}
                      <p
                        style={{
                          textAlign: "left",
                          lineHeight: "10px",
                          fontSize: "10px",
                          color: "#214C63",
                        }}
                      >
                        embassies, universities, retail stores or online
                        shopping pick-up/drop-off points
                      </p>{" "}
                    </div>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("specins", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Pick-up Instructions"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="input-col" sm={24} md={12}></Col>
                </Row>
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("collectionDate", {
                        rules: [
                          { required: true, message: "Pick-up date required" },
                        ],
                      })(
                        <StyledDatePicker
                          disabledDate={(current) => {
                            return (
                              current &&
                              current <
                                moment(moment().add(-1, "day")).endOf("day")
                            );
                          }}
                          style={{ width: "185%", height: "3.5em" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="input-col" sm={24} md={12}>
                    {" "}
                    <Form.Item>
                      {this.props.form.getFieldDecorator("collectionTime", {
                        rules: [
                          { required: true, message: "Pick-up time required" },
                        ],
                      })(
                        <StyledSelect1
                          placeholder="Pick-up Time"
                          style={{
                            width: "185%",
                            lineHeight: "28px",
                          }}
                        >
                          <Option value="08:00:00-17:00:00">
                            Between 8am - 5pm
                          </Option>
                          <Option value="08:00:00-16:00:00">
                            Between 8am - 4pm
                          </Option>
                          <Option value="13:00:00-17:00:00">
                            Between 1pm - 5pm
                          </Option>
                          {/* <Option value="anytimeparcel">Anytime Parcel</Option> */}
                        </StyledSelect1>
                      )}
                    </Form.Item>
                  </Col>
                </Row>{" "}
                <h1
                  className="get-quote-header"
                  style={{
                    backgroundColor: "#2482DB",
                    borderRadius: "0.1em",
                    fontSize: "24px",
                    color: "white",
                    marginTop: "0.5em",
                    paddingBottom: "0em",
                    border: "1px solid #2482db",
                  }}
                >
                  <img
                    className="number-1"
                    src={receiverTwo}
                    alt="Logo"
                    style={{
                      position: "absolute",
                      transform: "scale(0.8)",
                      left: "1.5em",
                    }}
                  ></img>{" "}
                  Receiver’s Information
                </h1>
                <h1
                  className="get-quote-notice1"
                  style={{
                    textAlign: "left",
                    color: "#214C63",
                    fontSize: "11px",
                    marginTop: "0.5em",
                    marginBottom: "0.5em",
                    paddingBottom: "0.8em",
                    paddingTop: "0.8em",
                    borderTop: "0px solid #2482db",
                  }}
                >
                  Please ensure that you are available at this address in the{" "}
                  <br /> afternoon between 12:00-17:00
                </h1>{" "}
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("destpers", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Company (if applicable)"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("destpercontact", {
                        rules: [
                          { required: true, message: "Contact name required" },
                        ],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Contact name"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("destperphone", {
                        rules: [
                          { required: true, message: "Phone number required" },
                        ],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Telephone"
                          maxLength={10}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("destpercell", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Cellphone"
                          maxLength={10}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("destperadd1", {
                        rules: [
                          {
                            required: true,
                            message: "Street address required",
                          },
                        ],
                      })(
                        <Input
                          style={{
                            width: "185%",
                            height: "3.5em",
                            marginBottom: "0.5em",
                          }}
                          placeholder="Delivery Street Address"
                        />
                      )}
                      {this.props.form.getFieldDecorator("destperadd2", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{
                            width: "185%",
                            height: "3.5em",
                            marginBottom: "0.5em",
                          }}
                          placeholder=""
                        />
                      )}
                      {this.props.form.getFieldDecorator("destperadd3", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder=""
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("origtown", {
                        rules: [{ required: false }],
                        initialValue: this.state.origtown
                          ? this.state.origtown
                          : "BOKSBURG",
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          disabled={true}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row style={{ maxWidth: "68em", margin: "auto" }}>
                <Col span={12}>
                  <Form.Item name="emailreceiver" label="Email Receiver Details">
                    <Input autoComplete="on" />
                  </Form.Item>
                </Col>
              </Row> */}
                <Row style={{ maxWidth: "68em", margin: "auto" }}>
                  <Col className="input-col" sm={24} md={12}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator("specinstruction", {
                        rules: [{ required: false }],
                      })(
                        <Input
                          style={{ width: "185%", height: "3.5em" }}
                          placeholder="Delivery Instructions"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  className="calculate-button"
                  type="submit"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#2482db",
                    color: "white",
                    margin: "auto",
                    width: "30%",
                    minWidth: "10em",
                    height: "2.8em",
                    marginTop: "2em",
                    marginBottom: "2em",
                    borderRadius: "3em",
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                >
                  Confirm & Pay
                </Button>
              </Form>
            </Card>
          ) : null}
          {this.state.currentStep === 3 ? (
            <Card
              className="get-quote-card-1"
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
                margin: "auto",
                width: "70%",
                minWidth: "30em",
                maxWidth: "70em",
                border: "0px solid #42A5F6",
                borderRadius: "1em",
                textAlign: "center",
                top: "3em",
              }}
            >
              <img
                className="back-button"
                src={backButton}
                onClick={() => this.prev()}
                alt="Logo"
                style={{
                  position: "absolute",
                  top: "54%",
                  left: "0em",
                  cursor: "pointer",
                }}
              ></img>{" "}
              <Descriptions
                className="get-quote-header-1"
                title="Pick-Up Details"
                bordered
              >
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Company (if applicable)"
                >
                  {this.state.summary.origpers}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Contact name"
                >
                  {this.state.summary.origpercontact}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Telephone"
                >
                  {this.state.summary.origperphone}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Cellphone"
                >
                  {this.state.summary.origpercell}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Pick-up Street Address"
                >
                  {this.state.summary.origperadd1}
                  <br />
                  {this.state.summary.origperadd2}
                  <br />
                  {this.state.summary.origperadd3}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Pick-up Town"
                >
                  {this.state.summary.origtown}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Pick-up Instructions"
                >
                  {this.state.summary.specins}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Pick-up Date"
                >
                  {this.state.summary.collectionDate}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Pick-up Time"
                >
                  {this.state.summary.collectionTime}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                className="get-quote-header-1"
                title="Delivery Details"
                bordered
              >
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Company (if applicable)"
                >
                  {this.state.summary.destpers}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Contact name"
                >
                  {this.state.summary.destpercontact}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Telephone"
                >
                  {this.state.summary.destperphone}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Cellphone"
                >
                  {this.state.summary.destpercell}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Delivery Street Address"
                >
                  {this.state.summary.destperadd1}
                  <br />
                  {this.state.summary.destperadd2}
                  <br />
                  {this.state.summary.destperadd3}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Delivery Town"
                >
                  {this.state.summary.desttown}
                </Descriptions.Item>
                <Descriptions.Item
                  className="get-quote-pay-decription"
                  span={2}
                  label="Delivery Instructions"
                >
                  {this.state.summary.specinstruction}
                </Descriptions.Item>
              </Descriptions>
              <Button
                className="calculate-button-1"
                style={{
                  backgroundColor: "#2482db",
                  color: "white",
                  margin: "auto",
                  width: "30%",
                  minWidth: "8em",
                  height: "2.8em",
                  marginTop: "2em",
                  marginBottom: "2em",
                  borderRadius: "3em",
                  fontSize: "22px",
                  fontWeight: "700",
                }}
                onClick={this.handlePay}
              >
                Pay
              </Button>
            </Card>
          ) : null}
          {this.state.complete ? (
            <Card
              className="get-quote-card"
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
                margin: "auto",
                width: "70%",
                minWidth: "30em",
                border: "0px solid #42A5F6",
                borderRadius: "1em",
                textAlign: "center",
                top: "2em",
              }}
            >
              {" "}
              <h1
                style={{
                  fontSize: "33px",
                  color: "black",
                  marginTop: "0.5em",
                  paddingBottom: "0em",
                  borderBottom: "0px solid #2482db",
                }}
              >
                Complete
              </h1>
              <h4
                style={{
                  fontSize: "24px",
                  color: "black",
                  marginTop: "0.5em",
                  paddingBottom: "0em",
                  borderBottom: "0px solid #2482db",
                }}
              >
                Thank you for choosing Without Delay Logistics
                <br /> we hope to see you soon.
              </h4>{" "}
              <h4
                style={{
                  fontSize: "20px",
                  color: "black",
                  marginTop: "0.5em",
                  paddingBottom: "0em",
                  fontWeight: "200",
                  borderBottom: "0px solid #2482db",
                }}
              >
                Please click on download to get your waybill
              </h4>
              <Button
                style={{
                  backgroundColor: "#2482db",
                  color: "white",
                  margin: "auto",
                  width: "30%",
                  minWidth: "10em",
                  height: "2.8em",
                  marginTop: "2em",
                  marginBottom: "3.8em",
                  borderRadius: "3em",
                  fontSize: "22px",
                  fontWeight: "700",
                }}
                onClick={this.downloadWaybill}
              >
                Download Waybill
              </Button>
            </Card>
          ) : null}
          {this.state.cancelled ? (
            <Card
              className="get-quote-card"
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
                margin: "auto",
                width: "70%",
                minWidth: "30em",
                border: "0px solid #42A5F6",
                borderRadius: "1em",
                textAlign: "center",
                top: "2em",
              }}
            >
              {" "}
              <h1
                style={{
                  fontSize: "33px",
                  color: "black",
                  marginTop: "0.5em",
                  paddingBottom: "0em",
                  borderBottom: "0px solid #2482db",
                }}
              >
                Payment canceled
              </h1>
              <h4
                style={{
                  fontSize: "24px",
                  color: "black",
                  marginTop: "0.5em",
                  paddingBottom: "0em",
                  borderBottom: "0px solid #2482db",
                }}
              >
                Thank you for choosing Without Delay Logistics.
              </h4>{" "}
              <h4
                style={{
                  fontSize: "20px",
                  color: "black",
                  marginTop: "0.5em",
                  paddingBottom: "0em",
                  fontWeight: "200",
                  borderBottom: "0px solid #2482db",
                }}
              >
                Please click on the relative link below .
              </h4>
              <Link to="/">
                <Button
                  style={{
                    backgroundColor: "#2482db",
                    color: "white",
                    margin: "auto",
                    width: "30%",
                    minWidth: "14em",
                    height: "2.8em",
                    marginTop: "2em",
                    marginBottom: "1em",
                    borderRadius: "3em",
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                  onClick={this.retryPayment}
                >
                  Retry Payment
                </Button>
              </Link>
              <Link to="/">
                <Button
                  style={{
                    backgroundColor: "#2482db",
                    color: "white",
                    margin: "auto",
                    width: "30%",
                    minWidth: "14em",
                    height: "2.8em",
                    marginTop: "2em",
                    marginBottom: "5.5em",
                    borderRadius: "3em",
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                  onClick={(e) => this.props.history.push("/")}
                >
                  Cancel Delivery
                </Button>
              </Link>
            </Card>
          ) : null}
        </Spin>{" "}
        <div
          className="quote-wave"
          style={{
            position: "relative",
            width: "100%",
            zIndex: -1,
            padding: "0 0px",
          }}
        >
          <BottomFooter />
        </div>{" "}
      </React.Fragment>
    );
  }
}

const WrappedSalesScreen = Form.create()(SalesScreen);
export default WrappedSalesScreen;
const serviceData = [
  {
    quoteno: "QTE00411074",
    rates: [
      {
        service: "ECO",
        name: "Economy Road",
        charge: "0.00",
        insurance: "0.00",
        customsvalue: "0.00",
        cartage: "100.00",
        outly: "0.00",
        docs: "0.00",
        handling: "0.00",
        cursurcharge: "0.00",
        totsurcharge: "0.00",
        subtotal: "100.00",
        vat: "14.00",
        total: "114.00",
        customsduties: "0.00",
        customsvat: "0.00",
        duedate: "2020-03-17",
        duetime: "14:00:00",
      },
      {
        service: "ONX",
        name: "Overnight Air",
        charge: "0.00",
        insurance: "0.00",
        customsvalue: "0.00",
        cartage: "300.00",
        outly: "0.00",
        docs: "0.00",
        handling: "0.00",
        cursurcharge: "0.00",
        totsurcharge: "0.00",
        subtotal: "300.00",
        vat: "42.00",
        total: "342.00",
        customsduties: "0.00",
        customsvat: "0.00",
        duedate: "2020-03-18",
        duetime: "10:30:00",
      },
      {
        service: "RDF",
        name: "Roadfreight",
        charge: "0.00",
        insurance: "0.00",
        customsvalue: "0.00",
        cartage: "200.00",
        outly: "0.00",
        docs: "0.00",
        handling: "0.00",
        cursurcharge: "0.00",
        totsurcharge: "0.00",
        subtotal: "200.00",
        vat: "28.00",
        total: "228.00",
        customsduties: "0.00",
        customsvat: "0.00",
        duedate: "2020-03-17",
        duetime: "N/A",
      },
    ],
  },
];
