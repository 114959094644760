import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Input,
  Steps,
  Popover,
  Spin,
  notification
} from "antd";
import styled from "styled-components";
import withoutLogo1 from "../static/images/w-delay-logo.png";
import { slide as Menu } from "react-burger-menu";

import smallWave from "../static/images/small-wave.png";
import withoutLogo from "../static/images/w-delay-logo.png";
import mapImg from "../static/images/home-map.webp";
import withoutLogoSmoke from "../static/images/w-delay-logo-smoke.png";
import cloudBack from "../static/images/cloud-back-1.png";
import personPic from "../static/images/person-pic.png";
import truckPic from "../static/images/truck-pic.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const { Header, Content, Footer } = Layout;
const { Step } = Steps;
const { Search } = Input;
const CloudBack = styled.img`
position: absolute;
transform:rotate(180deg);
top: 0em;
left: 100em;
width: 100%;
opacity:0.7;
z-index:0;
animation: mymove1 80s infinite;
@keyframes mymove1 {
  from {left: 100em;}
  to {left: -55em; opacity:0;}

}

`;
const TruckPic = styled.img`
  position: absolute;
  margin-top: -15%;
  width: 50%;
  max-width: 15em;
  z-index: 0;
  @media (max-width: 500px) {
    visibility: hidden !important;
    top: -20%;
    max-width: 8em;
  }
  transition: all 3s ease;
  @media (max-width: 750px) {
    visibility: hidden !important;
    top: -15%;

    max-width: 8em;
    transform: scale(0.9);
  }
`;
const PersonPic = styled.img`
  position: absolute;
  margin-top: -15%;
  right: 0em;
  width: 50%;
  max-width: 12em;
  z-index: 0;
  @media (max-width: 500px) {
    visibility: hidden !important;
    top: -20%;
    max-width: 8em;
  }
  transition: all 3s ease;
  @media (max-width: 750px) {
    visibility: hidden !important;
    top: -15%;

    max-width: 8em;
    transform: scale(0.9);
  }
`;
const CollectHeader = styled.h1`
  color: black;
  text-align: center;
  font-size: 35px;
  margin-top: -6em;
  margin-bottom: 5em;
  @media (max-width: 800px) {
    margin-bottom: 1.5em;
    font-size: 27px;
    margin-top: 1em;
    margin-left: 2.1em;
    padding-top: 4em;
  }
  @media (max-width: 750px) {
    font-size: 30px;
    margin-top: 1em;
    margin-left: 2.1em;
    margin-bottom: 2.1em;
  }
`;
const MobilePic = styled.img`
  position: absolute;
  right: -35%;
  bottom: -60%;
  @media (max-width: 1400px) {
    visibility: hidden;
  }
  @media (max-width: 500px) {
    visibility: visible;
    position: absolute;
    left: 25%;
    transform: scale(0.5);
    bottom: -30em;
  }
`;
const ParcelSearchMobile = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    background-color: transparent;
    border: 2px solid #2482db;

    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .ant-btn-primary {
    border-radius: 0.3em;
    margin-left: -0.25em;
    background-color: #2482db;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`;
const ParcelSteps = styled(Steps)`
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #2482db;
}.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background:  #2482db; transform:scale(2);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size:13px !important;
}

@media (max-width: 750px) {
  position:absolute;
  transform:scale(1);
  right:0em !important;
  
  display:none;
}
@media (max-width: 480px) {

  display:none !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: transparent;
    border: 1px solid #2482db;

  transform:scale(2);
}
`;
const ParcelStepsMobile = styled(Steps)`
display:none;
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #2482db;
}.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background:  #2482db; transform:scale(2);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size:13px !important;
}
@media (max-width: 750px) {display: flex;
 
  position:absolute;
  transform:scale(1);
  right:0em !important;

}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: transparent;
    border: 1px solid #2482db;

  transform:scale(2);
}
`;
const ParcelSearch = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    background-color: transparent;
    border-right: 0px solid red !important;
    border: 2px solid #2482db;

    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .ant-input-group .ant-input {
    width: 98.9%;
  }
  .ant-btn-primary {
    border-radius: 0.3em;
    margin-left: -0.25em;
    background-color: #2482db;
  }
`;
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
const TopHeader = props => {
  return (
    <Header className="header-home">
      <div className="header-links">
        {" "}
        <Link to="/get-quote">
          <a
            style={{
              color: "rgb(47, 99, 111)",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "2%"
            }}
          >
            Get a Quote
          </a>
        </Link>{" "}
        <Link
          style={{
            color: "#2F636F",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%"
          }}
          to="/community-projects"
        >
          Community Projects
        </Link>{" "}
        <Link
          style={{
            color: "#2F636F",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%"
          }}
          to="/contact-us"
        >
          Contact Us
        </Link>{" "}
        <Link to="/track-trace">
          <ParcelSearch
            className="track-parcel"
            style={{
              marginTop: "0.6em",
              width: "23em",
              paddingLeft: "4%"
            }}
            placeholder="Track my parcel"
            enterButton="Track"
            size="large"
            onSearch={value => {
              props.fetchWaybillDetails(value);
              window.waybillNumber = value;
              // props.history.push("/track-trace")
            }}
          />
        </Link>
      </div>
      <Menu burgerBarClassName={"my-class"} right>
        <ul id="menu">
          <img
            className="home-logo"
            src={withoutLogo1}
            alt="Logo"
            style={{
              position: "absolute",
              top: "0em",
              left: "3em",
              paddingLeft: "0em",
              zIndex: 0
            }}
          ></img>{" "}
          <div class="pin1"></div>
          <div class="pulse1"></div>
        </ul>
        <ParcelSearchMobile
          id="IdTracker"
          className="track-parcel"
          style={{
            position: "absolute",
            marginTop: "-6em",
            paddingLeft: "10%",
            paddingRight: "20%",
            width: "100%",
            zIndex: 2
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={value => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        />

        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/">
          Home
        </Link>
        <Link
          style={{ fontSize: "20px", color: "#2482db" }}
          to="/community-projects"
        >
          Community Projects
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/contact-us">
          Contact Us
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/get-quote">
          Get a Quote
        </Link>
      </Menu>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <div style={{ textAlign: "center" }} className="footer-watermark">
      <p
        style={{
          color: "white",
          textAlign: "center",
          paddingTop: "1em",
          fontSize: "12px",
          fontSize: "12px"
        }}
      >
        Copyright © 2020. Without Delay Logistics All rights reserved.
      </p>
    </div>
  );
};
const Notify = (type, title, message, duration) => {
  notification[type]({
    message: title,
    description: message
      ? message.message
        ? message.message
        : message
      : message,
    duration: duration ? duration : 4.5
  });
};
class SalesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waybill: window.waybillNumber,
      loading: false,
      details: undefined,
      currentStep: -1,
      isData: true
    };
  }
  fetchWaybillDetails = value => {
    this.setState({ waybill: value }, () => {
      this.componentWillMount();
    });
  };
  componentWillMount = () => {
    if (this.state.waybill) {
      this.setState({ loading: true });
      fetch(
        "https://www.vcsappcloud.com/withoutdelay/api/track/trackntrace?waybill=" +
          this.state.waybill,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8"
          }
        }
      ).then(async res => {
        if (!res.ok) {
          this.setState({ loading: false });
          Notify("error", "Error retrieving waybill details");
        }
        this.setState({ loading: false, details: await res.json() });
        Notify("success", "Retrieved waybill details");
        this.checkCurrentStep();
      });
    }
  };
  checkCurrentStep = () => {
    if (this.state.details) {
      let results = this.state.details.results;
      if (results.length > 0) {
        this.setState({ isData: true }, () => {
          var currentStepTo = 0;
          for (var i = 0; i < results.length; i++) {
            if (results[i].eventtype === "O  ") {
              if (currentStepTo === 0) {
                currentStepTo = 1
              }
            }
            if (results[i].eventtype === "") {
              if (currentStepTo === 0 || currentStepTo === 1) {
                currentStepTo = 2
              }
            }
            if (results[i].eventtype.search("Y") > -1 || results[i].eventtype.search("P") > -1) {
              if (currentStepTo === 0 || currentStepTo === 1 || currentStepTo === 2) {
                currentStepTo = 3
              }
            }
          }
          this.setState({ currentStep: currentStepTo });
        });
      } else {
        this.setState({ isData: false, currentStep: -1 });
      }
    } else {
      this.setState({ currentStep: -1 });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div
          id="home"
          style={{
            top: "0em",
            border: "0px solid #8888882c",
            boxShadow: "none",
            width: "100vw",
            zIndex: "0",
            height: "97vh",
            minHeight: "50em",

            overflowX: "hidden"
          }}
        >
          <Link to="/">
            <img
              className="home-logo"
              src={withoutLogo}
              alt="Logo"
              style={{
                marginTop: "0.3em",
                paddingLeft: "1em"
              }}
            ></img>
          </Link>
          <TopHeader
            history={this.props.history}
            fetchWaybillDetails={this.fetchWaybillDetails}
          />
          <Spin spinning={this.state.loading}>
            <div
              className="tracker-line-track-trace"
              style={{
                position: "relative",
                width: "100%",
                maxWidth: "1000px",
                margin: "auto",
                padding: "0 24px",
                marginTop: "26em",
                transition: "all 3s ease"
              }}
            >
              {" "}
              
              {!this.state.isData ? (
                <h3
                  className="waybill-error-text"
                  style={{
                    marginTop: "4em",
                    marginLeft: "2.7em",
                    textAlign: "center",
                    color: "red"
                  }}
                >
                  Waybill does not exist or there is currently no tracking data
                  available.
                </h3>
              ) : <div><CollectHeader>
              <Fade duration={1100} delay={200} left cascade>
                {this.state.currentStep === 3 ? "Your Package has been delivered" : "Your Package is on its way"}
              </Fade>
            </CollectHeader>{" "}
            {/*} <MobilePic src={mobilePic} /> */}
            <TruckPic
              style={{
                left:
                  this.state.currentStep === 1
                    ? "25%"
                    : this.state.currentStep === 2
                    ? "52%"
                    : this.state.currentStep === 3
                    ? "68%"
                    : "0%"
              }}
              src={truckPic}
            />
            <PersonPic className="person-pic" src={personPic} />
            <ParcelSteps
              progressDot={customDot}
              current={this.state.currentStep}
            >
              <Step title="Collection Created" description="" />
              <Step title="Parcel Collected" description="" />
              <Step title="Parcel Out for Delivery" description="" />
              <Step title="Parcel Delivered" description="" />
            </ParcelSteps>
            <ParcelStepsMobile
              current={this.state.currentStep}
              progressDot={customDot}
            >
              <Step title="Collection Created" description="" />
              <Step title="Parcel Collected" description="" />
              <Step title="Parcel Out for Delivery" description="" />
              <Step title="Parcel Delivered" description="" />
            </ParcelStepsMobile></div>}
              {/* <Card
                className="parcel-details-card"
                style={{
                  width: "100%",
                  height: "10em",
                  backgroundColor: "#42A5F6",
                  borderRadius: "10em",
                  textAlign: "center",
                  marginTop: "-6em"
                }}
              >
            
                <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                  <Col sm={24} md={8} lg={8}>
                    {" "}
                    <p
                      style={{
                        color: " white",
                        fontSize: "17px",
                        marginBottom: "0.5em"
                      }}
                    >
                      Collect from
                    </p>
                    <Input
                      disabled
                      style={{
                        width: "80%",
                        height: "3em",
                        borderRadius: "4em",
                        textAlign: "center"
                      }}
                      placeholder="Boksburg"
                    />
                  </Col>
                  <Col sm={24} md={8} lg={8}>
                    {" "}
                    <p
                      style={{
                        color: " white",
                        fontSize: "17px",
                        marginBottom: "0.5em"
                      }}
                    >
                      Deliver to
                    </p>
                    <Input
                      disabled
                      style={{
                        width: "80%",
                        height: "3em",
                        borderRadius: "4em",
                        textAlign: "center"
                      }}
                      placeholder="23 Kareeboom st."
                    />
                  </Col>
                  <Col sm={24} md={8} lg={8}>
                    {" "}
                    <p
                      style={{
                        color: " white",
                        fontSize: "17px",
                        marginBottom: "0.5em"
                      }}
                    >
                      Estimated weight
                    </p>
                    <Input
                      disabled
                      style={{
                        width: "80%",
                        height: "3em",
                        borderRadius: "4em",
                        textAlign: "center"
                      }}
                      placeholder="2.5"
                    />
                  </Col>
                </Row>
              </Card>  */}
            </div>
          </Spin>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            margin: "auto",
            padding: "0 0px",

            marginTop: "-1em"
          }}
        >
          {" "}
          <img
            src={smallWave}
            alt="Logo"
            style={{
              zIndex: -1,
              position: "absolute",
              bottom: "-1em",
              left: 0,
              width: "100vw",
              minHeight: "10em",
              minWidth: "60em"
            }}
          ></img>
          <BottomFooter />
        </div>
      </React.Fragment>
    );
  }
}

export default SalesScreen;
