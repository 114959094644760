import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.css";
import HomeScreen from "./Pages/home-page";
import TrackTrace from "./Pages/track-trace";
import ContactUs from "./Pages/contact-us";
import CommunityProjects from "./Pages/community-projects";
import GetQuote from "./Pages/get-quote";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <BrowserRouter basename={"/"}>
    <Switch>
      <Route exact path="/" component={HomeScreen} />
      <Route path="/track-trace" component={TrackTrace} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/community-projects" component={CommunityProjects} />
      <Route path="/get-quote" component={GetQuote} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
