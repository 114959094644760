import React, { Component } from "react";
import "./welcome_.css";

const ERROR_SPACING = 0;
let ErrorTypeColor1 = "red";
let ErrorTypeColor = "red";
let fontSize = "15px";

class FormErrors extends Component {
  render() {
    if (this.props.errorType && this.props.errorType.match(/Success.*/)) {
      ErrorTypeColor = "green";
    }

    return (
      <>
        <div
          className="errortype0"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor
          }}
        >
          {this.props.errorType}
        </div>
        <div
          className="errortype1"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType1}
        </div>
        <div
          className="errortype2"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType2}
        </div>
        <div
          className="errortype3"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType3}
        </div>
        <div
          className="errortype4"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor
          }}
        >
          {this.props.errorType4}
        </div>
        <div
          className="errortype5"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType5}
        </div>
        <div
          className="errortype6"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType6}
        </div>
        <div
          className="errortype7"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType7}
        </div>
        <div
          className="errortype8"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType8}
        </div>
        <div
          className="errortype9"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType9}
        </div>
        <div
          className="errortype10"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType10}
        </div>
        <div
          className="errortype11"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType12}
        </div>
        <div
          className="errortype12"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType12}
        </div>
        <div
          className="errortype13"
          style={{
            fontSize: fontSize,
            marginTop: ERROR_SPACING + "px",
            color: ErrorTypeColor1
          }}
        >
          {this.props.errorType13}
        </div>
      </>
    );
  }
}

export default FormErrors;
