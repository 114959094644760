import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Input,
  Popover,
  Steps,
} from "antd";

import styled from "styled-components";
import phone from "../static/images/phone.png";
import box from "../static/images/box.jpg";
import abc from "../static/images/abc.jpg";
import shop from "../static/images/shop.jpg";
import withoutLogo from "../static/images/w-delay-logo-white.png";
import withoutLogo1 from "../static/images/w-delay-logo.png";
import spinPin from "../static/images/spin-pin.png";
import { slide as Menu } from "react-burger-menu";
import homeImg from "../static/images/home-image.png";
import mapImgBig from "../static/images/home-map.webp";
import mapImg from "../static/images/map-image.png";
import circle1 from "../static/images/circle-1.png";
import circle2 from "../static/images/circle-2.png";
import circle3 from "../static/images/circle-3.png";
import blueBack from "../static/images/blue-back.png";
import mobilePic from "../static/images/mobile-pic.png";
import startingLine from "../static/images/starting-line.png";
import wavePic from "../static/images/wave-pic.png";
import pinPick from "../static/images/pin-pic.png";
import handshake from "../static/images/handshake.png";
import creative from "../static/images/creative-idea.png";
import deliveryTruck from "../static/images/delivery-truck.png";
import courierHome from "../static/images/courier-home.png";
import courierWheel from "../static/images/courier-wheel.png";
import courierWheel2 from "../static/images/courier-wheel-2.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Link } from "react-router-dom";
const { Header, Content, Footer } = Layout;
const { Step } = Steps;
const { Search } = Input;
const { Option } = Select;
const HomeImage = styled.img`
position: absolute;
width:100vw;
height:122vh;
min-height:64em;

min-width:65em;
top: -0em;
z-index: -1;
  
`;
const MapImage = styled.img`
position: absolute;
width:100vw;
height:110vh;
min-height:64em;

min-width:65em;
top: -0em;
z-index: -1;
  
`;
const MapImageFooter = styled.img`
position: absolute;
opacity: 0.1;
bottom: 0em;
height: 20em;

overflow: hidden;

  
`;
const BGTriangles = styled.img`
margin-top: 0em;
z-index: -2;
width: 100vw;
min-width:100em;
position: absolute;

  
`;
const MobilePic = styled.img`
  right: 35%;
  width: 18em;
  margin-bottom: -24em;
  @media (max-width: 765px) {
    display: none;
  }
`;

const PinPick = styled.img`
position: absolute;
top:-4em;
left: 4.1em;
width: 2.5em;
max-width: 2.5em;
z-index: 0;
@media (max-width: 1000px) {
  left:45%;
 
}

`;

const CollectHeader = styled.h1`
  color: #104270;
  text-align: center;
  font-size: 40px;
  margin-top: 3em;
  @media (max-width: 800px) {
    font-size: 28px;
    margin-top: 1em;
    margin-bottom: -3em;
  }
`;

const HomeParagraph = styled.p`
  color: white;
  font-size: 22px;
  margin-top: 2em;
  font-weight: 200;
  margin-left: -2em;
  text-align: left;
  @media (max-height: 750px) {
    font-size: 21px;
    margin-left: -1.5em;
  }
  @media (max-width: 1420px) {
  }
`;
const GetStartedButton = styled(Button)`
  margin-left: -25em;
  bottom: 23.5em;
  left: -37em;
  color: white;
  border: none;

  background-color: #10427086;
  font-size: 16px;
  width: 12em;
  height: 2.7em;
  border-radius: 0.4em;
`;
const GetQuoteButton = styled(Button)`
  margin: auto;
  color: #2482db;
  left: 0;
  right: 0;
  border: none;
  position: absolute;
  margin-top: 3em;
  font-weight: 700;
  font-size: 21px;
  background-color: white;
  width: 12em;
  height: 2.7em;
  border-radius: 0.4em;

  @media (max-width: 1000px) {
  }
`;
const ProjectCards = styled(Card)`
  margin-bottom: 3em;
  top: -0em;

  height: 27em;

  width: 20em;
  min-width: 20em;
  border-radius: 1em;
  box-shadow: 0px 3px 30px 5px rgba(199, 199, 199, 0.356);
  border: 0px solid #2482db;
  @media (max-width: 1000px) {
    top: 10em;
    width: 80%;
    left: -10% !important;
  }
  @media (max-width: 770px) {
    width: 80%;
    top: 12em;
    left: 0% !important;
  }
`;
const AboutHeader1 = styled.h1`
  margin-top: 5em;
  margin-bottom: 1em;
  margin-left: 1em;
  color: #2482db;
  font-size: 30px;
  text-align: left;
  font-weight: 400;
  @media only screen and (max-width: 580px) {
    margin-bottom: -1em;
  }
`;
const AboutHeader2 = styled.h1`
  color: #2482db;
  margin-top: 2.1em;
  mmargin-bottom: 1em;
  margin-left: 1em;
  font-size: 30px;
  text-align: left;
  font-weight: 400;
  @media only screen and (max-width: 580px) {
    margin-bottom: -0.7em;
  }
`;
const AboutHeader3 = styled.h1`
  margin-top: -0.7em;
  margin-bottom: 1em;
  margin-left: 1em;
  color: #2482db;
  font-size: 30px;
  line-height: 38px;
  text-align: left;
  font-weight: 400;
  white-space: nowrap;
  @media (max-width: 580px) {
    margin-top: 1.2em;
  }
    @media only screen and (max-width: 580px) {
      margin-bottom: -0.7em;
    }
`;
const AboutParagraph1 = styled.p`
  color: grey;
  font-size: 19px;
  font-weight: 400;
  margin-left: -0.8em;
  text-align: left;
  margin-top: 3em;
  @media (max-width: 580px) {
    margin-top: 4em;
  }
`;
const AboutParagraph2 = styled.p`
  color: grey;
  font-size: 19px;
  font-weight: 400;
  text-align: left;
  margin-top: 1.5em;
  @media (max-width: 580px) {
    margin-top: 3.3em;
  }
`;
const AboutParagraph3 = styled.p`
  color: grey;
  font-size: 19px;
  font-weight: 400;
  text-align: left;
  margin-top: 3em;
  @media (max-width: 580px) {
    margin-top: 4em;
  }
`;
const StartTrackingButton = styled(Button)`
margin-left: 0em;
bottom: -0em;
color: white;
border:none;
background-color: #2482DB;
font-size: 16px;
width: 12em;
height: 2.7em;
border-radius: 0.4em;

`;
const ParcelSearch = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    color: white;
    background-color: transparent;
    border: 2px solid white;
    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }

  .ant-btn-primary {
    border: none;
    border-radius: 0.3em;
    margin-left: -0.25em;
    color: #2482db;
    background-color: white;
  }
`;
const ParcelSearchMobile = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    color: black;
    background-color: transparent;
    border: 2px solid #2482db;
    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }

  .ant-btn-primary {
    border: none;
    border-radius: 0.3em;
    margin-left: -0.25em;
    color: white;
    background-color: #2482db;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`;
const ParcelSteps = styled(Steps)`
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #008cff;
    transform: scale(2);
  }
`;
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
function handleChange(value) {
  console.log(`selected ${value}`);
}
const TopHeader = (props) => {
  return (
    <Header>
      <div className="header-links">
        {" "}
        <Link to="/get-quote">
          <a
            style={{
              color: "white",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "2%",
            }}
          >
            Get a Quote
          </a>
        </Link>{" "}
        <Link
          style={{
            color: "white",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
          }}
          to="/community-projects"
        >
          Community Projects
        </Link>{" "}
        <Link
          style={{
            color: "white",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
          }}
          to="/contact-us"
        >
          Contact Us
        </Link>
        <Link
          style={{
            color: "white",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
          }}
          onClick={e => window.open("https://withoutdelaylogistics.pperfect.com/", "__blank")}
        >
          Track and Trace
        </Link>
        {/* <ParcelSearch
          id="IdTracker"
          className="track-parcel"
          style={{
            marginTop: "0.6em",
            width: "23em",
            paddingLeft: "4%",
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={(value) => {
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        /> */}
      </div>
      <Menu right>
        <ul id="menu">
          <img
            className="home-logo"
            src={withoutLogo1}
            alt="Logo"
            style={{
              position: "absolute",
              top: "0em",
              left: "3em",
              paddingLeft: "0em",
              zIndex: 0,
            }}
          ></img>{" "}
          <div class="pin1"></div>
          <div class="pulse1"></div>
        </ul>
        {/* <ParcelSearchMobile
          id="IdTracker"
          className="track-parcel"
          style={{
            position: "absolute",
            marginTop: "-6em",
            paddingLeft: "10%",
            paddingRight: "20%",
            width: "100%",
            zIndex: 2,
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={(value) => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        /> */}

        <Link
          style={{
            fontSize: "20px",
            color: "#2482db",
            textDecoration: "underline",
          }}
          to="/"
        >
          Home
        </Link>
        <Link
          style={{ fontSize: "20px", color: "#2482db" }}
          to="/community-projects"
        >
          Community Projects
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/contact-us">
          Contact Us
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/get-quote">
          Get a Quote
        </Link>
        <Link
          style={{ fontSize: "20px", color: "#2482db" }}
          onClick={e => window.open("https://withoutdelaylogistics.pperfect.com/", "__blank")}
        >
          Track and Trace
        </Link>
      </Menu>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <div style={{ textAlign: "center" }} className="footer-watermark">
      <p style={{ color: "white", fontSize: "12px" }}>
        Copyright © 2020. Without Delay Logistics All rights reserved.
      </p>
    </div>
  );
};

class SalesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waybill: window.waybillNumber,
      loading: false,
      details: undefined,
      currentStep: -1,
      isData: true,
    };
  }
  fetchWaybillDetails = (value) => {
    this.setState({ waybill: value }, () => {
      this.componentWillMount();
    });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          {" "}
          <Col
            style={{
              height: "100vh",
            }}
            span={24}
          >
            <div
              id="home"
              style={{
                scrollBehavior: "smooth",
                border: "0px solid #8888882c",
                boxShadow: "none",
                width: "100vw",
                zIndex: "0",
                height: "100vh",
                minHeight: "55em",
              }}
            >
              <HomeImage src={homeImg} alt="Logo" />{" "}
              <MapImage src={mapImg} alt="Logo" />{" "}
              <Row
                className="home-content"
                style={{
                  top: "37%",
                  maxWidth: "90em",
                  margin: "auto",
                  paddingLeft: "1em",
                }}
              >
                <Col span={12} style={{}}>
                  {" "}
                  <div class="pin"></div>
                  <div class="pulse"></div>
                  <div
                    style={{
                      left: "0em",
                      width: "110%",
                      position: "absolute",
                      color: "white",
                      height: "40%",
                      textAlign: "center",
                      fontSize: "55px",
                    }}
                  >
                    <div className="home-header-h1">
                      <Fade duration={1100} delay={200}>
                        <p>A New Brand of Courier</p>
                      </Fade>
                    </div>
                    <Fade duration={1100} delay={200}>
                      <HomeParagraph className="home-sub-paragraph">
                        We have successfully introduced market leading <br />
                        express delivery and logistics services within <br />
                        South Africa and other emerging economies.
                      </HomeParagraph>{" "}
                    </Fade>
                    <Row className="get-started-line" gutter={24}>
                      {" "}
                      <img
                        style={{ opacity: "0" }}
                        src={startingLine}
                        alt="Logo"
                      />
                      <a href="#middle">
                        <GetStartedButton className="get-started-button">
                          Let’s get Started
                        </GetStartedButton>
                      </a>
                    </Row>
                  </div>{" "}
                </Col>
                <Col span={12}>
                  <img
                    className="courier-mockup"
                    src={courierHome}
                    alt="Logo"
                  />
                  <img
                    className="courier-wheel"
                    src={courierWheel}
                    alt="Logo"
                  />
                  <img
                    className="courier-wheel-2"
                    src={courierWheel2}
                    alt="Logo"
                  />
                </Col>{" "}
              </Row>
              <Link to="/">
                <img
                  className="home-logo"
                  src={withoutLogo}
                  alt="Logo"
                  style={{
                    marginTop: "0.8em",
                    paddingLeft: "3em",
                  }}
                ></img>{" "}
              </Link>
              <TopHeader
                history={this.props.history}
                fetchWaybillDetails={this.fetchWaybillDetails}
              />
            </div>{" "}
          </Col>
        </Row>
        <BGTriangles className="blue-back" src={blueBack} alt="Logo" />
        <Row
          id="middle"
          gutter={24}
          style={{
            maxWidth: "80em",
            margin: "auto",
            marginTop: "7em",
            paddingLeft: "5em",
          }}
        >
          <Col
            className="what-we-col"
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              marginTop: "4em",
              borderRadius: "1em",
            }}
            sm={6}
            md={12}
          >
            <img
              className="circle-1"
              style={{
                position: "absolute",
                top: "8em",
                left: "-0.5em",
                opacity: 0.4,
              }}
              src={circle1}
              alt="Logo"
            />
            <Fade duration={1100} delay={200} top cascade>
              <AboutHeader1 className="about-header-transform">
                What We Do
              </AboutHeader1>
              <Fade duration={1100} delay={200}>
                <AboutParagraph1 className="about-paragraph-transform">
                  We have successfully introduced market leading express
                  <br />
                  delivery and logistics services in S.A and other emerging
                  <br />
                  economies. Our breadth of services includes express courier
                  <br />
                  delivery in S.A for International and Domestic companies.
                </AboutParagraph1>{" "}
              </Fade>
            </Fade>
          </Col>{" "}
          <Col
            className="what-we-col-img"
            style={{
              textAlign: "left",
              backgroundColor: "transparent",
              marginTop: "3em",
            }}
            sm={6}
            md={12}
          >
            {" "}
            <img
              className="what-we-col-pin"
              src={spinPin}
              alt="Logo"
              style={{
                position: "absolute",
                marginTop: "13.3em",
                marginBottom: "1em",
                marginLeft: "10.6em",
                width: "4em",
                zIndex: "-2",
              }}
            ></img>{" "}
            <Fade duration={1100} delay={0} bottom>
              <img
                className="what-we-col-img"
                src={deliveryTruck}
                alt="Logo"
                style={{
                  position: "absolute",
                  marginTop: "8em",
                  marginBottom: "2em",
                  marginLeft: "9em",
                  width: "24em",
                  zIndex: "-2",
                }}
              ></img>{" "}
            </Fade>
          </Col>
        </Row>{" "}
        <Row
          className="about-cols"
          style={{
            margin: "auto",
            paddingLeft: "4em",
            marginBottom: "7em",
          }}
        >
          <Col
            className="how-we-col-img"
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              marginTop: "0em",
            }}
            sm={6}
            md={12}
          >
            <img
              className="how-we-col-pin"
              src={spinPin}
              alt="Logo"
              style={{
                position: "absolute",
                marginTop: "16.5em",
                marginBottom: "1em",
                marginLeft: "2.5em",
                width: "5em",

                zIndex: 1,
              }}
            ></img>{" "}
            <Fade duration={800} delay={0} bottom cascade>
              <img
                className="how-we-col-img"
                src={creative}
                alt="Logo"
                style={{
                  marginTop: "5.4em",
                  marginLeft: "-6em",
                  marginBottom: "2em",
                  paddingLeft: "0em",
                  width: "25em",
                }}
              ></img>{" "}
            </Fade>
          </Col>
          <Col
            className="how-we-col"
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              marginTop: "6em",
              borderRadius: "1em",
              height: "23em",
            }}
            sm={6}
            md={12}
          >
            {" "}
            <img
              className="circle-2"
              style={{
                position: "absolute",
                top: "2em",
                left: "-0.5em",
                opacity: 0.4,
              }}
              src={circle2}
              alt="Logo"
            />
            <Fade duration={1100} delay={200} top cascade>
              <AboutHeader2 className="about-header-transform">
                How do we do it ?
              </AboutHeader2>
            </Fade>
            <Fade duration={1100} delay={200}>
              <AboutParagraph2 className="about-paragraph-transform">
                Asset-light business model underlines all of the strategic{" "}
                <br />
                decisions we make. It allows us to be flexible and service{" "}
                <br />
                ready to execute last-mile delivery solutions and quickly <br />
                respond to the changing customer needs.
              </AboutParagraph2>{" "}
            </Fade>
          </Col>
        </Row>
        <Row
          className="about-cols"
          style={{
            borderRadius: "1em",
            maxWidth: "80em",
            margin: "auto",
            marginTop: "13em",
            paddingLeft: "0em",
          }}
        >
          {" "}
          <Col
            className="our-future-col"
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              marginTop: "-5em",
            }}
            sm={6}
            md={12}
          >
            <img
              className="circle-3"
              style={{
                position: "absolute",
                top: "-4em",
                left: "-0.5em",
                opacity: 0.4,
              }}
              src={circle3}
              alt="Logo"
            />{" "}
            <Fade duration={1100} delay={200} top cascade>
              <AboutHeader3 className="about-header-transform">
                Our Future in the Market
              </AboutHeader3>
            </Fade>
            <Fade duration={1100} delay={200}>
              <AboutParagraph3 className="about-paragraph-transform">
                We believe our future growth will be based on thinking and
                <br />
                acting differently and ensuring we have great people on our
                <br />
                team to provide an effective service and make a difference in
                <br />
                the market place.
              </AboutParagraph3>{" "}
            </Fade>
          </Col>{" "}
          <Col
            className="our-future-col-img"
            style={{
              textAlign: "left",
              backgroundColor: "transparent",
              marginTop: "-5em",
              borderRadius: "1em",
              height: "15em",
            }}
            sm={6}
            md={12}
          >
            <img
              className="our-future-col-pin"
              src={spinPin}
              alt="Logo"
              style={{
                position: "absolute",
                marginTop: "9em",
                marginBottom: "1em",
                marginLeft: "13.7em",
                width: "4em",

                zIndex: 1,
              }}
            ></img>
            <Fade duration={900} delay={0} right cascade>
              <img
                className="our-future-col-img"
                src={handshake}
                alt="Logo"
                style={{
                  marginTop: "-3em",
                  marginLeft: "8em",
                  width: "25em",
                }}
              ></img>{" "}
            </Fade>
          </Col>{" "}
        </Row>{" "}
        <img
          className="wave-img-big"
          src={wavePic}
          alt="Logo"
          style={{
            position: "absolute",
            width: "100vw",
            minWidth: "60em",
            height: "66em",
            zIndex: -1,
            marginTop: "10em",
          }}
        />
        <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "22em" }}>
          <h1
            className="how-it-header"
            style={{
              margin: "auto",
              marginBottom: "2em",
              color: "white",
              fontSize: "40px",
              textAlign: "center",
              fontWeight: "700",
            }}
          >
            How it works
          </h1>{" "}
          <Col className="card-hover-up" sm={24} md={12} lg={6}>
            <ProjectCards>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  {" "}
                  <img
                    style={{ width: "6em", marginTop: "1em" }}
                    src={phone}
                    alt="Logo"
                  />{" "}
                  <h1
                    style={{
                      marginTop: "0.35em",
                      fontFamily: "Autobus-Bold",
                      color: "rgb(24, 70, 114)",
                      fontSize: "30px",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    Step 1
                  </h1>{" "}
                  <h1
                    style={{
                      fontFamily: "Autobus-Bold",
                      marginTop: "-0.7em",
                      color: "rgb(24, 70, 114)",
                      fontSize: "25px",
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                  >
                    Select Location
                  </h1>{" "}
                  <p
                    style={{
                      marginTop: "0em",
                      color: "grey",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "center",
                      fontFamily: "Autobus-Bold",
                    }}
                  >
                    Enter parcel dimensions and location information for both
                    the sender and the receiver of the parcel.
                  </p>{" "}
                </Col>{" "}
              </Row>
            </ProjectCards>{" "}
          </Col>
          <Col className="card-hover-up" sm={24} md={12} lg={6}>
            <ProjectCards>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "12em", marginTop: "-1em" }}
                    src={box}
                    alt="Logo"
                  />{" "}
                  <h1
                    style={{
                      fontFamily: "Autobus-Bold",
                      marginTop: "-1em",
                      color: "rgb(24, 70, 114)",
                      fontSize: "30px",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    Step 2
                  </h1>{" "}
                  <h1
                    style={{
                      marginTop: "-0.7em",
                      color: "rgb(24, 70, 114)",
                      fontSize: "25px",
                      textAlign: "center",
                      lineHeight: "25px",
                      fontFamily: "Autobus-Bold",
                      fontWeight: "500",
                    }}
                  >
                    Choose service type of the delivery options
                  </h1>{" "}
                  <p
                    style={{
                      marginTop: "0em",
                      color: "grey",
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Autobus-Bold",
                      textAlign: "center",
                    }}
                  >
                    Choose between 3 types of delivery options (Economy Road,
                    Overnight Air and Roadfreight).
                  </p>{" "}
                </Col>{" "}
              </Row>
            </ProjectCards>{" "}
          </Col>
          <Col
            className="card-hover-up"
            sm={24}
            md={12}
            lg={6}
            style={{ textAlign: "center" }}
          >
            <ProjectCards>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "12em", marginTop: "-1em" }}
                    src={abc}
                    alt="Logo"
                  />{" "}
                  <h1
                    style={{
                      marginTop: "-1em",
                      color: "rgb(24, 70, 114)",
                      fontSize: "30px",
                      textAlign: "center",
                      fontFamily: "Autobus-Bold",
                      fontWeight: "700",
                    }}
                  >
                    Step 3
                  </h1>{" "}
                  <h1
                    style={{
                      marginTop: "-0.7em",
                      color: "rgb(24, 70, 114)",
                      fontSize: "25px",
                      textAlign: "center",
                      fontFamily: "Autobus-Bold",
                      fontWeight: "500",
                    }}
                  >
                    Enter details
                  </h1>{" "}
                  <p
                    style={{
                      marginTop: "0em",
                      color: "grey",
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Autobus-Bold",
                      textAlign: "center",
                    }}
                  >
                    Fill in standard details of both the sender and the receiver
                    to find out the cost price and service available.
                  </p>{" "}
                </Col>{" "}
              </Row>
            </ProjectCards>{" "}
          </Col>
          <Col
            className="card-hover-up"
            sm={24}
            md={12}
            lg={6}
            style={{ textAlign: "center" }}
          >
            <ProjectCards>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "12em", marginTop: "-1em" }}
                    src={shop}
                    alt="Logo"
                  />{" "}
                  <h1
                    style={{
                      marginTop: "-1em",
                      color: "rgb(24, 70, 114)",
                      fontSize: "30px",
                      textAlign: "center",
                      fontFamily: "Autobus-Bold",
                      fontWeight: "700",
                    }}
                  >
                    Step 4
                  </h1>{" "}
                  <h1
                    style={{
                      marginTop: "-0.7em",
                      color: "rgb(24, 70, 114)",
                      fontSize: "25px",
                      textAlign: "center",
                      fontFamily: "Autobus-Bold",
                      fontWeight: "500",
                    }}
                  >
                    Pay
                  </h1>{" "}
                  <p
                    style={{
                      marginTop: "0em",
                      color: "grey",
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Autobus-Bold",
                      textAlign: "center",
                    }}
                  >
                    Pay the full amount calculated for your parcel and you are
                    done. A courier will be on its way to collect and deliver
                    your package.
                  </p>{" "}
                </Col>{" "}
              </Row>{" "}
            </ProjectCards>{" "}
          </Col>{" "}
        </Row>{" "}
        <Link to="/get-quote">
          <GetQuoteButton>Get a Quote</GetQuoteButton>
        </Link>
        <div
          style={{
            position: "relative",
            width: "100%",
            padding: "0 0px",
            marginTop: "11em",
          }}
        >
          <BottomFooter />
        </div>{" "}
      </React.Fragment>
    );
  }
}

export default SalesScreen;
