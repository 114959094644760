import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Input,
  Steps,
  Popover
} from "antd";
import styled from "styled-components";
import smallWave from "../static/images/small-wave.png";
import mapImg from "../static/images/home-map.webp";
import mapImgBig from "../static/images/home-map.webp";
import withoutLogo from "../static/images/w-delay-logo.png";
import withoutLogo1 from "../static/images/w-delay-logo.png";
import { slide as Menu } from "react-burger-menu";
import community1 from "../static/images/community-pic-1.png";

import orientHills from "../static/images/orient-hills.png";

import "./sales-screen.css";
import Fade from "react-reveal/Fade";

import { Link } from "react-router-dom";
const { Header } = Layout;

const { Search } = Input;

const ParcelSearch = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    background-color: transparent;
    border-right: 0px solid red !important;
    border: 2px solid #2482db;

    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .ant-input-group .ant-input {
    width: 98.9%;
  }
  .ant-btn-primary {
    border-radius: 0.3em;
    margin-left: -0.25em;
    background-color: #2482db;
  }
`;
const ParcelSearchMobile = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    background-color: transparent;
    border: 2px solid #2482db;

    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .ant-btn-primary {
    border-radius: 0.3em;
    margin-left: -0.25em;
    background-color: #2482db;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`;
const HomeHeader = styled.h1`
  color: #42a5f6;
  text-align: center;
  font-size: 55px;
  margin-top: 1em;
  @media (max-height: 750px) {
    font-size: 43px;
    margin-top: 0em;
  }
`;

const TopHeader = props => {
  return (
    <Header className="header-home">
      <div className="header-links">
        {" "}
        <Link to="/get-quote">
          <a
            style={{
              color: "rgb(47, 99, 111)",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "2%"
            }}
          >
            Get a Quote
          </a>
        </Link>{" "}
        <Link
          style={{
            color: "#2F636F",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
            textDecoration: "underline"
          }}
          to="/community-projects"
        >
          Community Projects
        </Link>{" "}
        <Link
          style={{
            color: "#2F636F",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%"
          }}
          to="/contact-us"
        >
          Contact Us
        </Link>{" "}
        <ParcelSearch
          className="track-parcel"
          style={{
            marginTop: "0.6em",
            width: "23em",
            paddingLeft: "4%"
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={value => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        />
      </div>{" "}
      <Menu burgerBarClassName={"my-class"} right>
        <ul id="menu">
          <img
            className="home-logo"
            src={withoutLogo1}
            alt="Logo"
            style={{
              position: "absolute",
              top: "0em",
              left: "3em",
              paddingLeft: "0em",
              zIndex: 0
            }}
          ></img>{" "}
          <div class="pin1"></div>
          <div class="pulse1"></div>
        </ul>
        <ParcelSearchMobile
          id="IdTracker"
          className="track-parcel"
          style={{
            position: "absolute",
            marginTop: "-6em",
            paddingLeft: "10%",
            paddingRight: "20%",
            width: "100%",

            zIndex: 2
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={value => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        />

        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/">
          Home
        </Link>
        <Link
          style={{
            fontSize: "20px",
            color: "#2482db",
            textDecoration: "underline"
          }}
          to="/community-projects"
        >
          Community Projects
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/contact-us">
          Contact Us
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/get-quote">
          Get a Quote
        </Link>
      </Menu>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "1.4em"
      }}
      className="footer-watermark-community"
    >
      <img
        src={smallWave}
        alt="Logo"
        style={{
          zIndex: -1,

          bottom: 0,
          left: 0,
          width: "100vw",
          minHeight: "10em",
          minWidth: "60em"
        }}
      ></img>
      <p
        style={{
          color: "white",
          marginTop: "-2.7em",
          fontSize: "12px",
          bottom: 0,
          right: 0,
          left: 0
        }}
      >
        Copyright © 2020. Without Delay Logistics All rights reserved.
      </p>
    </div>
  );
};
function handleChange(value) {
  console.log(`selected ${value}`);
}
class SalesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waybill: window.waybillNumber,
      loading: false,
      details: undefined,
      currentStep: -1,
      isData: true
    };
  }
  fetchWaybillDetails = value => {
    this.setState({ waybill: value }, () => {
      this.componentWillMount();
    });
  };

  render() {
    return (
      <React.Fragment>
        {" "}
        <img
          src={mapImg}
          alt="Logo"
          style={{
            position: "absolute",
            opacity: 0.1,
            top: "-29em",

            zIndex: -2
          }}
        />
        <div
          id="home"
          style={{
            top: "0em",
            border: "0px solid #8888882c",
            boxShadow: "none",
            width: "100vw",
            zIndex: "0",
            height: "100vh",
            overflowX: "hidden"
          }}
        >
          <Link to="/">
            <img
              className="home-logo"
              src={withoutLogo}
              alt="Logo"
              style={{
                marginTop: "0.3em",
                paddingLeft: "1em"
              }}
            ></img>
          </Link>
          <TopHeader
            history={this.props.history}
            fetchWaybillDetails={this.fetchWaybillDetails}
          />{" "}
          <HomeHeader className="home-header-social">
            <Fade duration={1100} delay={200}>
              Blessings & Community Giving
            </Fade>
          </HomeHeader>
          <div>
            <p
              className="social-paragraph"
              style={{
                textAlign: "center",
                marginTop: "1em",
                fontSize: "18px",
                paddingLeft: "1em",
                paddingRight: "1em"
              }}
            >
              Social responsibility is an ethical theory, in which individuals
              <br />
              are accountable for fulfilling all out civic duty, the actions of
              an
              <br />
              individual must benefit the whole of society. In this way, there
              <br />
              must be a balance between economic growth and the welfare of
              society
              <br />
              and the environment. We proudly support Orient Hills Day Care,
              <br /> People in Need of Water, Education and stationery on Youth
              Day in South Africa for people in need <br />
              <a href="http://www.orienthills.org/home/">orienthills.org</a>
            </p>
          </div>
          <Row gutter={24}>
            <Col
              style={{
                textAlign: "center"
              }}
              span={24}
            >
              <img
                style={{
                  textAlign: "center",
                  marginTop: "0em",

                  borderRadius: "2em"
                }}
                src={orientHills}
                alt="Logo"
              />{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              style={{
                textAlign: "center"
              }}
              span={24}
            >
              <img
                className="community-pic"
                style={{
                  textAlign: "center",
                  marginTop: "0em",

                  borderRadius: "1.5em"
                }}
                src={community1}
                alt="Logo"
              />
            </Col>
          </Row>{" "}
          <div
            style={{
              position: "relative",
              width: "100%",
              margin: "auto",
              padding: "0 0px",
              marginTop: "5em"
            }}
          >
            <BottomFooter />
          </div>
        </div>{" "}
      </React.Fragment>
    );
  }
}

export default SalesScreen;
