import React, { Component } from "react";
import { Layout, Button, Input, Select, Popover, Col, Row } from "antd";
import Clock from "react-live-clock";
import styled from "styled-components";
import withoutLogo from "../static/images/w-delay-logo.png";
import withoutLogoWhite from "../static/images/w-delay-logo-white.png";
import homeImg from "../static/images/contact-image.png";
import withoutLogo1 from "../static/images/w-delay-logo.png";
import { slide as Menu } from "react-burger-menu";
import Modal from "react-responsive-modal";
import Fade from "react-reveal/Fade";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./sales-screen.css";
import { Link } from "react-router-dom";
import validator from "validator";
import FormError from "./FormErrors";
const sgMail = require("@sendgrid/mail");
const { TextArea } = Input;
const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Search } = Input;
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}
const HomeImage = styled.img`
position: absolute;
width:100vw;
min-height:67em;
max-height:70em;
top: -0em;
z-index: -1;
  }
`;

const ParcelSearch = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    color: white;
    background-color: transparent;
    border: 2px solid white;
    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }

  .ant-btn-primary {
    border: none;
    border-radius: 0.3em;
    margin-left: -0.25em;
    color: #2482db;
    background-color: white;
  }
`;
const ParcelSearchMobile = styled(Search)`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    background-color: transparent;
    border: 2px solid #2482db;

    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .ant-btn-primary {
    border-radius: 0.3em;
    margin-left: -0.25em;
    background-color: #2482db;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`;
const TopHeader = (props) => {
  return (
    <Header>
      <div className="header-links">
        {" "}
        <Link to="/get-quote">
          <a
            style={{
              color: "white",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "2%",
            }}
          >
            Get a Quote
          </a>
        </Link>{" "}
        <Link
          style={{
            color: "white",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
          }}
          to="/community-projects"
        >
          Community Projects
        </Link>{" "}
        <Link
          style={{
            color: "white",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "2%",
            textDecoration: "underline",
          }}
          to="/contact-us"
        >
          Contact Us
        </Link>{" "}
        <ParcelSearch
          id="IdTracker"
          className="track-parcel"
          style={{
            marginTop: "0.6em",
            width: "23em",
            paddingLeft: "4%",
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={(value) => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        />
      </div>{" "}
      <Menu right>
        <ul id="menu">
          <img
            className="home-logo"
            src={withoutLogo1}
            alt="Logo"
            style={{
              position: "absolute",
              top: "0em",
              left: "3em",
              paddingLeft: "0em",
              zIndex: 0,
            }}
          ></img>{" "}
          <div class="pin1"></div>
          <div class="pulse1"></div>
        </ul>
        <ParcelSearchMobile
          id="IdTracker"
          className="track-parcel"
          style={{
            position: "absolute",
            marginTop: "-6em",
            paddingLeft: "10%",
            paddingRight: "20%",
            width: "100%",
            zIndex: 2,
          }}
          placeholder="Track my parcel"
          enterButton="Track"
          size="large"
          onSearch={(value) => {
            props.fetchWaybillDetails(value);
            window.waybillNumber = value;
            props.history.push("/track-trace");
          }}
        />

        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/">
          Home
        </Link>
        <Link
          style={{ fontSize: "20px", color: "#2482db" }}
          to="/community-projects"
        >
          Community Projects
        </Link>
        <Link
          style={{
            fontSize: "20px",
            color: "#2482db",
            textDecoration: "underline",
          }}
          to="/contact-us"
        >
          Contact Us
        </Link>
        <Link style={{ fontSize: "20px", color: "#2482db" }} to="/get-quote">
          Get a Quote
        </Link>
      </Menu>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <div style={{ textAlign: "center" }} className="footer-watermark">
      {" "}
      <p style={{ color: "white", fontSize: "12px" }}>
        Copyright © 2020. Without Delay Logistics All rights reserved.
      </p>
    </div>
  );
};

class ContactUs extends Component {
  state = {
    open: false,
    successModal: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      successModal: false,
      name: "",
      email: "",
      waybillnumber: "",
      message: "",
      formValidity: false,
      submitDisabled: false,
      waybill: window.waybillNumber,
      loading: false,
      details: undefined,
      currentStep: -1,
      isData: true,
      formErrors: {
        name: "Please enter your name",
        email: "Please enter a valid Email",
        waybillnumber:
          "Please enter a valid waybil number containing 12 characters",
      },
    };

    const SENDGRID_API_KEY =
      "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";
    sgMail.setApiKey(SENDGRID_API_KEY);

    this.changeValue = this.changeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  fetchWaybillDetails = (value) => {
    this.setState({ waybill: value }, () => {
      this.componentWillMount();
    });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
  };
  componentDidMount() {
    document.body.scrollTop = 0;
  }
  onfocus = function () {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
  };
  onSubmit = (event) => {
    event.preventDefault();

    let formValidity = true;
    let errorType = undefined;
    let errorType1 = undefined;
    let errorType2 = undefined;
    let errorType3 = undefined;

    const MSG = {
      to: ["ianels182@gmail.com"],
      from: "info@withoutdelay.co.za",
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
            body, #bodyTable{background-color:white}
            #emailHeader{background-color:white}
            #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
            #emailFooter{background-color:#E1E1E1;}      
    </style>
  <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
    <center style="background-color:#E1E1E1;">
      <table border="0" cellpadding="0" cellspacing="0" height="%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
        <tr>
          <td align="center" valign="top" id="bodyCell">      
            <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
              <!-- HEADER ROW // -->
              <tr>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                        <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                          <tr>
                            <td valign="top" width="500" class="flexibleContainerCell">
  
                              <!-- CONTENT TABLE // -->
                              <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                             
                                  <td align="right" valign="middle" class="flexibleContainerBox">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                      <tr>
                                        <td align="left" class="textContent">
                                          <!-- CONTENT // -->
                                         
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>
              <!-- // END -->
            </table>     
            <!-- EMAIL BODY // -->   
            <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
              <!-- MODULE ROW // -->
              <tr>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="white">
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                       
                        <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                          <tr>
                            <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                              <!-- CONTENT TABLE // -->
                            
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                     <td  align="left" valign="top" width="500" class="flexibleContainerCell  ">   
                                      <img style="padding:1.4em;" src="https://www.withoutdelaycouriers.co.za/dev/static/media/w-delay-logo.8ed2bbe1.png"/>    <tr>
                                  <td align="center" valign="top" class="textContent">
                                    <h1 style="color:#006BA8;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                    <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: #006BA8;line-height:100%;">You have received a new contact request from <br /> the Without Delay website</h2>
                                   
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>

              <!-- MODULE ROW // -->
              
              <tr mc:hideable>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                        <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                          <tr>
                            <td valign="top" width="500" class="flexibleContainerCell">
                              <!-- CONTENT TABLE // -->
                              <table p align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                  <td align="center" valign="top" class="flexibleContainerBox">
                                   	<table border="0" cellspacing="0" cellpadding="0" >
									
															<tr style="font-family: sans-serif; font-size: 15px; letter-spacing: 1px; color:rgb(121, 121, 121)">
																<td class="role"><br /><br /><br /><br /><br />
                                <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.name}<br />
                                     <strong> Email </strong>&nbsp;&nbsp;: &nbsp;&nbsp;${this.state.email}<br />
                                     <strong> Waybil Number </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${this.state.waybillnumber}<br />
                          
                                     <strong> Message </strong>&nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.message}<br /><br /><br /><br /><br />
																</td>
															</tr>
															<table>
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>
              <!-- // MODULE ROW -->
  
  
              <!-- MODULE ROW // -->
             
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#006BA8">
                    <tr>
                      <td align="center" valign="top">
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                          <tr>
                            <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                          
                              <table border="0" cellpadding="30" cellspacing="0" width="100%">
                            
													
                                <tr>
                                  <td align="center" valign="top" class="textContent">
              
                                    <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: lightgray;line-height:135%;"> 2020 Without Delay Logistics</h2>
                     
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
           `,
    };

    if (this.state.name === "") {
      formValidity = false;
      errorType1 = this.state.formErrors.name;
    }
    if (!validator.isEmail(this.state.email)) {
      formValidity = false;
      errorType2 = this.state.formErrors.email;
    }
    if (!validator.isMobilePhone(this.state.waybillnumber)) {
      formValidity = false;
      errorType3 = this.state.formErrors.waybillnumber;
    }
    if (!formValidity) {
      this.setState({
        formValidity,
        errorType,
        errorType1,
        errorType2,
        errorType3,
      });
    } else {
      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async (res) => {
        if (!res.ok) {
          let response = await res.text();
          this.setState({
            formValidity,
            errorType: undefined,
            errorType1: undefined,
            errorType2: undefined,
            errorType3: undefined,
            submitDisabled: true,
            name: "",
            email: "",
            waybillnumber: "",

            message: "",
          });
        }
        this.setState({
          successModal: true,
          formValidity,
          errorType: undefined,
          errorType1: undefined,
          errorType2: undefined,
          errorType3: undefined,
          submitDisabled: true,
          name: "",
          email: "",
          waybillnumber: "",

          message: "",
        });
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <HomeImage className="contact-back" src={homeImg} alt="Logo" />
        <Link to="/">
          <img
            className="home-logo"
            src={withoutLogoWhite}
            alt="Logo"
            style={{
              marginTop: "0.8em",
              paddingLeft: "3em",
            }}
          ></img>{" "}
        </Link>
        <TopHeader
          history={this.props.history}
          fetchWaybillDetails={this.fetchWaybillDetails}
        />{" "}
        <Row
          style={{
            maxWidth: "120em",
            margin: "auto",
            paddingLeft: "6em",
          }}
        >
          {" "}
          <Col className="contact-col-1" sm={24} md={12}>
            <div
              style={{
                position: "relative",
                maxWidth: "700px",
                margin: "auto",
                padding: "0 24px",
                marginTop: "16em",
                marginLeft: "4%",
              }}
            >
              {" "}
              <h1
                className="contact-text"
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  marginTop: "-4em",
                  textAlign: "left",
                }}
              >
                {" "}
                <Fade duration={1100} delay={500} top>
                  <h1
                    style={{
                      color: "white",
                      fontSize: "50px",
                      fontWeight: 700,
                      lineHeight: "1em",
                    }}
                  >
                    Contact Us
                  </h1>
                </Fade>
              </h1>
              <Fade duration={1500} delay={500} bottom>
                <p
                  className="contact-text"
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: 400,
                    textAlign: "left",
                    marginTop: "-1em",
                    marginBottom: "2em",
                  }}
                >
                  Got a Question ?
                </p>
              </Fade>{" "}
              <h1
                style={{
                  fontSize: "17px",
                  zIndex: 9,

                  marginLeft: "1em",
                  marginTop: "0.5em",
                  color: "white",
                  position: "absolute",
                }}
              >
                First Name
              </h1>
              <Input
                required
                autoComplete="off"
                type="text"
                name="name"
                id="name"
                value={this.state.name}
                style={{
                  color: "white",
                  paddingTop: "2em",
                  paddingLeft: "1em",
                  height: "5em",
                  marginBottom: "-0.1em",
                  border: "1px solid white",
                  backgroundColor: "transparent",
                  borderRadius: "0em",
                }}
                size="large"
                onChange={(event) => this.changeValue(event)}
                placeholder=""
              />{" "}
              <Row>
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "17px",
                      zIndex: 9,

                      marginLeft: "1em",
                      marginTop: "0.5em",
                      color: "white",

                      position: "absolute",
                    }}
                  >
                    Email Address
                  </h1>
                  <Input
                    required
                    autocomplete="on"
                    name="email"
                    id="Email"
                    size="large"
                    style={{
                      color: "white",
                      paddingTop: "2em",
                      paddingLeft: "1em",
                      height: "5em",
                      borderRadius: "0em",
                      marginBottom: "2em",
                      border: "1px solid white",
                      backgroundColor: "transparent",
                    }}
                    type="email"
                    onChange={(event) => this.changeValue(event)}
                    value={this.state.email}
                    placeholder=""
                  />{" "}
                </Col>
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "17px",
                      zIndex: 9,

                      marginLeft: "1em",
                      marginTop: "0.5em",
                      color: "white",
                      position: "absolute",
                    }}
                  >
                    Package nr.
                  </h1>{" "}
                  <h1
                    style={{
                      fontSize: "14px",
                      zIndex: 9,
                      fontWeight: "300",
                      marginLeft: "8em",
                      marginTop: "0.8em",
                      color: "white",
                      position: "absolute",
                    }}
                  >
                    (Optional)
                  </h1>
                  <Input
                    required
                    minLength="12"
                    maxLength="12"
                    type="text"
                    name="waybillnumber"
                    id="waybillnumber"
                    style={{
                      color: "white",
                      paddingTop: "2em",
                      paddingLeft: "1em",
                      height: "5em",
                      borderRadius: "0em",
                      marginBottom: "2em",
                      border: "1px solid white",
                      borderLeft: "0px solid white",
                      backgroundColor: "transparent",
                    }}
                    value={this.state.waybillnumber}
                    size="large"
                    onChange={(event) => this.changeValue(event)}
                    placeholder=""
                  />
                </Col>
              </Row>
              <h1
                style={{
                  zIndex: 9,
                  fontSize: "17px",
                  marginLeft: "1em",
                  marginTop: "-1em",
                  color: "white",
                  position: "absolute",
                }}
              >
                Message
              </h1>
              <TextArea
                required
                type="text"
                id="message"
                name="message"
                autocomplete="off"
                style={{
                  color: "white",
                  paddingTop: "3em",
                  paddingLeft: "1em",
                  marginTop: "-2.1em",
                  borderRadius: "0em",
                  height: "13em",
                  fontSize: "1.1em",
                  border: "1px solid white",
                  backgroundColor: "transparent",
                }}
                onChange={(event) => this.changeValue(event)}
                placeholder=""
                value={this.state.message}
                autoSize={{ minRows: 6, maxRows: 6 }}
              />
              <Button
                style={{
                  backgroundColor: "wehite",
                  color: "#2482DB",
                  fontSize: "17px",
                  textAlign: "center",
                  fontWeight: 500,
                  width: "100%",
                  height: "4em",
                  border: "1px solid white",
                  marginTop: "0em",
                  borderRadius: "0em",
                }}
                className="submit-buttons"
                disabled={this.state.submitDisabled}
                onClick={(event) => this.onSubmit(event)}
                type="submit"
              >
                Send
              </Button>
              <FormError errorType={this.state.errorType} />
              <FormError errorType1={this.state.errorType1} />
              <FormError errorType2={this.state.errorType2} />
              <FormError errorType3={this.state.errorType3} />
            </div>{" "}
          </Col>{" "}
          <Col className="contact-col-2" sm={0} md={12}>
            {" "}
            <div
              style={{
                position: "relative",
                maxWidth: "700px",
                margin: "auto",
                padding: "0 24px",
                marginTop: "8em",
                marginLeft: "-10%",
              }}
            >
              <Clock class="clock" format={"HH:mm"} />
              <p
                style={{
                  textAlign: "right",
                  color: "white",
                  fontSize: "22px",
                  fontWeight: "200",
                }}
              >
                63 Kromdraai Road
                <br /> PO Box 353
                <br /> Lanseria
                <br />
                1748
              </p>{" "}
              <br />
              <p
                style={{
                  textAlign: "right",
                  color: "white",
                  fontSize: "22px",
                  fontWeight: "200",
                }}
              >
                Operations <br />
                Mark Johnson (083 321 6903)
                <br />
                Business Solutions and Customer Advisor <br />
                Carol Chitapi (083 321 6903)
              </p>
            </div>
          </Col>
        </Row>
        <Modal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
        >
          {" "}
          <img
            className="home-logo"
            src={withoutLogo}
            alt="Logo"
            style={{
              marginTop: "0.8em",
              paddingLeft: "-2em",
            }}
          ></img>{" "}
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Thank you for submitting your query. <br />
            We'll be in touch as soon as possible.
          </h3>
        </Modal>
        <div
          style={{
            position: "absolute",
            width: "100%",
            margin: "auto",
            padding: "0 0px",
            marginTop: "9em",
          }}
        >
          <BottomFooter />
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUs;
